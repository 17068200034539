import { Alert, Icon, IconButton, Snackbar, Switch, Tooltip } from "@mui/material";
import MyTable from "components/MyTable"
import PageLayout from "components/PageLayout"
import React from "react"
import ApiHelpers from "utils/ApiHelpers";
import Constants from "utils/Constant"
import { alpha, styled } from "@mui/material/styles"
import { green } from "@mui/material/colors";
import ModalMerchantForm from "./_ModalMerchantForm";
import Loading from "components/Loading";
import { userAuth } from "store";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[600],
    "&:hover": {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: green[600],
  },
}));

const ARR_TABLE_HEADER = [
  {
    key: "name",
    label: "Nama",
    styles: { borderLeft: "none", justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "airport",
    label: "Bandara",
    styles: { borderLeft: "none", justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "type",
    label: "Tipe",
    styles: { borderLeft: "none", justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "terminal",
    label: "Terminal",
    styles: { borderLeft: "none", justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "address",
    label: "Alamat",
    styles: { borderLeft: "none", justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "reduce_percent",
    label: "Persen Pengurang Transaksi",
    styles: { borderLeft: "none", justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "is_active_bool",
    label: "Status",
    styles: { justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "",
    label: "#",
    styles: {},
    sort: "",
    sortable: false,
    className: "fixed_position",
  },
];

export default class Merchant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLabel: "Data Merchant",

      isFetched: true,
      isRefreshing: false,
      isLoadMore: false,
      isLoadPage: false,
      fetchData: [],
      fetchDataShow: 0,
      fetchDataTotal: 0,
      fetchDataPage: 1,
      fetchDataPagination: null,
      inputSearch: "",
      inputSort: "",
      inputFilter: "",

      user: null,
    };
    this.firstMount = true;
  }

  componentDidMount() {
    if (this.firstMount) {
      this.firstMount = false;
      document.title = `Data Merchant | ${Constants.appName}`;
      let user = userAuth();
      this.setState({ user }, this.onRefresh);
    }
  }

  componentWillUnmount() {}

  // ====================================================================================
  // ========== INITIALIZE, GET DATA ====================================================
  // ====================================================================================
  onRefresh = () => {
    this.setState(
      {
        isFetched: true,
        isRefreshing: false,
        isLoadMore: false,
        isLoadPage: false,
        fetchData: [],
        fetchDataShow: 0,
        fetchDataTotal: 0,
        fetchDataPage: 1,
      },
      this.fetchData
    );
  };
  fetchData = async (isLoadMore = true) => {
    let apiEndPoint = "client";
    let params = {
      search: this.state.inputSearch,
      sort: this.state.inputSort,
      page: this.state.fetchDataPage,
      limit: 15,
    };
    let response = await ApiHelpers.get(apiEndPoint, params);
    // console.log(response)
    if (
      response.status === 200 &&
      response.results.data !== undefined &&
      response.results.data
    ) {
      let data = response.results.data;
      let pagination = response.results.pagination;

      let fetchData = data;
      if (pagination.current !== 1 && isLoadMore) {
        fetchData = [...this.state.fetchData, ...data];
      }
      this.setState({
        fetchDataTotal: pagination.total_data,
        fetchDataShow: this.state.fetchDataShow + pagination.total_display,
        fetchData,
        fetchDataPagination: response.results.pagination,
      });
    } else {
      this.setState({
        fetchDataShow: 0,
        fetchDataTotal: 0,
        fetchDataPagination: null,
      });
    }

    this.setState(
      {
        isFetched: false,
        isRefreshing: false,
        isLoadMore: false,
        isLoadPage: false,
      }
      // this.setQueryString(isLoadMore)
    );
  };
  onLoadMore = () => {
    if (this.state.fetchDataShow < this.state.fetchDataTotal) {
      this.setState(
        { isLoadMore: true, fetchDataPage: this.state.fetchDataPage + 1 },
        this.fetchData
      );
    } else {
      this.setState({ isLoadMore: false });
    }
  };
  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================
  onConnectToAccurateListener = async (item, index) => {
    this.ref_LoadingDialog.onShowDialog();
    let url = 'client/accurate_auth_code'
    let response = await ApiHelpers.post(url, {
      id: item.id,
      redirect_uri: window.location.origin + '/oauth/callback'
    });
    if(response !== undefined && response.status === 200){
      if(response.results !== undefined && response.results.url !== undefined && response.results.url !== ''){
        window.location.href = response.results.url;
      }
    }
    this.ref_LoadingDialog.onCloseDialog();
  }
  // ====================================================================================
  // ========== RENDER SECTION ==========================================================
  // ====================================================================================
  render() {
    let showAdd = false;
    if(this.state.user !== null){
      if(this.state.user.id === '1'){
        showAdd = true
      }
    }
    return (
      <PageLayout>
        <MyTable
          ref={(value) => (this.ref_MyTableCustom = value)}
          title={`${this.state.pageLabel}`}
          arrHeading={ARR_TABLE_HEADER}
          dataTable={this.state.fetchData}
          isFetched={this.state.isFetched}
          isLoadPage={this.state.isLoadPage}
          inputFilter={this.state.inputFilter}
          fetchDataPagination={this.state.fetchDataPagination}
          onPageListeners={(fetchDataPage) => {
            this.setState({ fetchDataPage, isLoadPage: true }, () =>
              this.fetchData(false)
            );
          }}
          onSort={(inputSort) => {
            this.setState(
              { inputSort, isLoadPage: true, fetchDataPage: 1 },
              () => this.fetchData(false)
            );
          }}
          renderItems={(item, index) => this.renderItems(item, index, showAdd)}
          inputSearch={this.state.inputSearch}
          placeholderSearch={`Tuliskan disini cari ${this.state.pageLabel.toLowerCase()}...`}
          onSearch={(inputSearch) => {
            this.ref_MyTableCustom.setSearchValue("");
            this.setState({ inputSearch }, this.onRefresh);
          }}
          onClearSearch={() => {
            this.ref_MyTableCustom.setSearchValue("");
            this.setState({ inputSearch: "" }, this.onRefresh);
          }}
          showAdd={showAdd}
          onAdd={() => {
            this.ref_ModalMerchantForm.onShowDialog("add", null, -1); 
          }}
          onLoadMore={() => {
            this.onLoadMore();
          }}
          onReload={() => {
            this.onRefresh();
          }}
        />

        {/* LOADING DIALOG */}
        <Loading ref={(value) => (this.ref_LoadingDialog = value)} />

        {/* SNACKBAR & ALERT DIALOG */}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          style={{ bottom: 80 }}
          open={this.state.showSnackbar}
          autoHideDuration={4600}
          onClose={() => {
            this.setState({ showSnackbar: false, messageSnackbar: "" });
          }}
        >
          <Alert
            severity={this.state.severitySnackbar}
            onClose={() => {
              this.setState({ showSnackbar: false, messageSnackbar: "" });
            }}
            sx={{ width: "100%" }}
          >
            {this.state.messageSnackbar}
          </Alert>
        </Snackbar>

        <ModalMerchantForm
          ref={(value) => (this.ref_ModalMerchantForm = value)}
          onSubmit={(response, type = "", index = -1) => {
            let severitySnackbar = "warning";
            if (response.status === 200) {
              if (type !== "" && type === "activated" && index >= 0) {
                let fetchData = this.state.fetchData;
                fetchData[index].is_active_bool = !fetchData[index].is_active_bool;
                this.setState({ fetchData });
              } else {
                this.onRefresh();
              }
              this.ref_ModalMerchantForm.onCloseDialog();
              severitySnackbar = "success";
            }
            setTimeout(() => {
              this.setState({
                showSnackbar: true,
                messageSnackbar: response.message,
                severitySnackbar: severitySnackbar,
              });
            }, 100);
          }}
        />
      </PageLayout>
    );
  }

  renderItems(item, index, showAdd) {
    return (
      <tr key={index}>
        <td>
          <div
            className={`${"list_item text_align_left"}`}
            style={{ opacity: item.is_active_bool ? 1.0 : 0.5 }}
          >
            <div className={"title"}>Nama:</div>
            <div className={"value"}>{item.name}</div>
          </div>
        </td>
        <td>
          <div
            className={`${"list_item text_align_left"}`}
            style={{ opacity: item.is_active_bool ? 1.0 : 0.5 }}
          >
            <div className={"title"}>Bandara:</div>
            <div className={"value"}>{item.airport}</div>
          </div>
        </td>
        <td>
          <div
            className={`${"list_item text_align_left"}`}
            style={{ opacity: item.is_active_bool ? 1.0 : 0.5 }}
          >
            <div className={"title"}>Tipe:</div>
            <div className={"value"}>{item.type}</div>
          </div>
        </td>
        <td>
          <div
            className={`${"list_item text_align_left"}`}
            style={{ opacity: item.is_active_bool ? 1.0 : 0.5 }}
          >
            <div className={"title"}>Terminal:</div>
            <div className={"value"}>{item.terminal}</div>
          </div>
        </td>
        <td>
          <div
            className={`${"list_item text_align_left"}`}
            style={{ opacity: item.is_active_bool ? 1.0 : 0.5 }}
          >
            <div className={"title"}>Alamat:</div>
            <div className={"value"}>{item.address}</div>
          </div>
        </td>
        <td>
          <div
            className={`${"list_item text_align_right"}`}
            style={{ opacity: item.is_active_bool ? 1.0 : 0.5 }}
          >
            <div className={"title"}>Persen Pengurang Transaksi:</div>
            <div className={"value"}>{item.reduce_percent || '0'}%</div>
          </div>
        </td>
        <td>
          <div
            className={`${"list_item_status"}`}
            style={{ opacity: item.is_active_bool ? 1.0 : 0.5 }}
          >
            <div className={"title"}>Status:</div>
            <div className={"value"}>
              <div
                className={"label"}
                style={{
                  color: item.is_active_bool ? "#27ae60" : "#e74c3c",
                }}
              >
                {item.is_active_bool ? "Aktif" : "Tidak Aktif"}
              </div>
              {showAdd &&
                <div className={"switch"}>
                  <Tooltip title={item.is_active_bool ? "Non Aktifkan" : "Aktifkan"}>
                    <GreenSwitch
                      checked={item.is_active_bool}
                      onClick={() => {
                        this.ref_ModalMerchantForm.onShowDialog("activated", item, index)
                      }}
                    />
                  </Tooltip>
                </div>
              }
            </div>
          </div>
        </td>
        <td className="fixed_position">
          <div
            className={`${"list_button"}`}
            style={{ opacity: item.is_active_bool ? 1.0 : 0.5 }}
          >
            {item.accurate_auth_code === '' && showAdd && 
              <Tooltip title="Hubungkan Ke Accurate">
                <IconButton
                  aria-label="detail"
                  onClick={() => {
                    this.onConnectToAccurateListener(item, index);
                  }}
                >
                  <Icon style={{ fontSize: 22, color: "#2980b9" }} >cloud_sync</Icon>
                </IconButton>
              </Tooltip>
            }
            {showAdd && 
              <>
                <Tooltip title="Ubah Data Client">
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      this.ref_ModalMerchantForm.onShowDialog("edit", item, index)
                    }}
                  >
                    <Icon style={{ fontSize: 22, color: "#f39c12" }}>edit</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Hapus Data Client">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      this.ref_ModalMerchantForm.onShowDialog("delete", item, index)
                    }}
                  >
                    <Icon style={{ fontSize: 22, color: "#e74c3c" }}>delete</Icon>
                  </IconButton>
                </Tooltip>
              </>
            }
            <Tooltip title="Detail Merchant">
              <IconButton
                aria-label="edit"
                onClick={() => {
                  this.ref_ModalMerchantForm.onShowDialog("detail", item, index)
                }}
              >
                <Icon style={{ fontSize: 22, color: "#999" }}>info_outlined</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </td>
      </tr>
    );
  }
}