const userAuth = () => {
    let response = localStorage.getItem("user_session")
    return JSON.parse(response)
}

const setUserAuth = (data) => {
    localStorage.setItem('user_session', JSON.stringify(data))
    return true
}

const updateMenuUser = (menu) => {
    let session = localStorage.getItem('user_session')
    let data = JSON.parse(session)
    if(data !== null && data.menu_array !== undefined){
        data.menu_array = menu
        setUserAuth(data)
    }
}

const clearSession = () => {
    localStorage.removeItem('user_session')
}

export { userAuth, setUserAuth, updateMenuUser, clearSession }