import { Alert, Icon, IconButton, Snackbar, Tooltip } from "@mui/material";
import MyTable from "components/MyTable";
import PageLayout from "components/PageLayout";
import moment from "moment";
import React from "react";
import ApiHelpers from "utils/ApiHelpers";
import CommonHelper from "utils/CommonHelper";
import Constants from "utils/Constant";
import ModalSyncDataFilter from "./_ModalSyncDataFilter";
import Loading from "components/Loading";
import ModalSyncDataDetail from "./_ModalSyncDataDetail";
import ExportExcel, { EXPORT_EXCEL_STYLE_NUM_FMT } from "utils/ExportExcel";

const ARR_TABLE_HEADER = [
  {
    key: "client_name",
    label: "Nama Merchant",
    styles: { borderLeft: "none", justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "status",
    label: "Status Sync",
    styles: { justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "invoice_number",
    label: "Nomor Invoice",
    styles: { justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "type",
    label: "Tipe",
    styles: { justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "invoice_datetime",
    label: "Tanggal Transaksi",
    styles: { justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "invoice_total_amount",
    label: "Nominal Transaksi",
    styles: { justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "invoice_total_amount_reduce",
    label: "Nominal Transaksi Sync",
    styles: { justifyContent: "space-between" },
    sort: "",
    sortable: true,
  },
  {
    key: "",
    label: "#",
    styles: {},
    sort: "",
    sortable: false,
    className: "fixed_position",
  },
];

export default class SyncData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLabel: "Data Webhook",

      isFetched: true,
      isRefreshing: false,
      isLoadMore: false,
      isLoadPage: false,
      fetchData: [],
      fetchDataShow: 0,
      fetchDataTotal: 0,
      fetchDataPage: 1,
      fetchDataPagination: null,
      inputSearch: "",
      defaultSort: "-id",
      inputSort: "",
      inputFilter: [],

      severitySnackbar: "",
      showSnackbar: false,
      messageSnackbar: "",
    };
    this.firstMount = true;
  }

  componentDidMount() {
    if (this.firstMount) {
      this.firstMount = false;
      document.title = `Data Webhook | ${Constants.appName}`;
      this.onRefresh();
    }
  }

  componentWillUnmount() {}

  // ====================================================================================
  // ========== INITIALIZE, GET DATA ====================================================
  // ====================================================================================
  onRefresh = () => {
    this.setState(
      {
        isFetched: true,
        isRefreshing: false,
        isLoadMore: false,
        isLoadPage: false,
        fetchData: [],
        fetchDataShow: 0,
        fetchDataTotal: 0,
        fetchDataPage: 1,
      },
      this.fetchData
    );
  };
  fetchData = async (isLoadMore = true) => {
    let apiEndPoint = "webhook";
    let params = {
      search: this.state.inputSearch,
      sort:
        this.state.inputSort === ""
          ? this.state.defaultSort
          : this.state.inputSort,
      page: this.state.fetchDataPage,
      filter: this.state.inputFilter,
      limit: 15,
    };
    let response = await ApiHelpers.get(apiEndPoint, params);
    // console.log(response)
    if (
      response.status === 200 &&
      response.results.data !== undefined &&
      response.results.data
    ) {
      let data = response.results.data;
      let pagination = response.results.pagination;

      let fetchData = data;
      if (pagination.current !== 1 && isLoadMore) {
        fetchData = [...this.state.fetchData, ...data];
      }
      this.setState({
        fetchDataTotal: pagination.total_data,
        fetchDataShow: this.state.fetchDataShow + pagination.total_display,
        fetchData,
        fetchDataPagination: response.results.pagination,
      });
    } else {
      this.setState({
        fetchDataShow: 0,
        fetchDataTotal: 0,
        fetchDataPagination: null,
      });
    }

    this.setState(
      {
        isFetched: false,
        isRefreshing: false,
        isLoadMore: false,
        isLoadPage: false,
      }
      // this.setQueryString(isLoadMore)
    );
  };
  onLoadMore = () => {
    if (this.state.fetchDataShow < this.state.fetchDataTotal) {
      this.setState(
        { isLoadMore: true, fetchDataPage: this.state.fetchDataPage + 1 },
        this.fetchData
      );
    } else {
      this.setState({ isLoadMore: false });
    }
  };
  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================
  onClickDetailListener = async (item, index) => {
    this.ref_LoadingDialog.onShowDialog();
    let response = await ApiHelpers.get("webhook/detail", { id: item.id });
    if (
      response.status === 200 &&
      response.results !== undefined &&
      response.results.data !== undefined
    ) {
      this.ref_ModalSyncDataDetail.onShowDialog(response.results.data);
    } else {
      this.setState({
        severitySnackbar: "warning",
        showSnackbar: true,
        messageSnackbar: "Detail data tidak ditemukan.",
      });
    }
    this.ref_LoadingDialog.onCloseDialog();
  };
  onDownloadFileLocalExcel = async () => {
    this.ref_LoadingDialog.onShowDialog();

    let severitySnackbar = "";
    let showSnackbar = false;
    let messageSnackbar = "";
    let fetchData = [];
    let processedData = [
      // {
      //   invoice_datetime: "",
      //   client_name: "",
      //   status: "",
      //   invoice_number: "",
      //   invoice_total_amount: "",
      //   invoice_total_amount_reduce: "",
      //   item_sku: "",
      //   item_name: "",
      //   item_unit: "",
      //   item_qty: "",
      //   item_price: "",
      //   item_price_payload: "",
      // },
    ];

    let summary = {
      invoice_total: 0,
      invoice_total_amount: 0,
      invoice_total_amount_reduce: 0,
      item: 0,
      item_qty: 0,
      item_price: 0,
      item_price_payload: 0,
      item_tot_price: 0,
      item_tot_price_payload: 0,
    };

    let apiEndPoint = "webhook/export";
    let params = {
      search: this.state.inputSearch,
      sort:
        this.state.inputSort === ""
          ? this.state.defaultSort
          : this.state.inputSort,
      page: this.state.fetchDataPage,
      filter: this.state.inputFilter,
      pagination_bool: false,
    };

    let subTitleArray = [];
    if (params.search) {
      subTitleArray.push({
        value: `Pencarian: ${params.search}`,
      });
    }

    if (Array.isArray(params.filter)) {
      let subtitleFilter = {
        client_id: "Semua Merchant",
        client_status: "Semua Status",
        invoice_datetime: "Semua Tanggal Transaksi",
      };
      const subtitlePrefix = {
        client_id: "Merchant: ",
        client_status: "Status: ",
        invoice_datetime: "Tanggal Transaksi: ",
      };
      params.filter.forEach((item) => {
        if (item.field === "invoice_datetime") {
          const filterDate = item.value.split("::");
          const filterDateStart = moment(filterDate[0]).format("DD MMM YYYY");
          const filterDateEnd = moment(filterDate[1]).format("DD MMM YYYY");
          if (filterDateStart != filterDateEnd) {
            item.title = `${filterDateStart} - ${filterDateEnd}`;
          } else {
            item.title = `${filterDateStart}`;
          }
        }
        subtitleFilter[item.field] = `${subtitlePrefix[item.field]}`;
        if (item?.title) {
          subtitleFilter[item.field] += `${item.title}`;
        } else if (item?.value) {
          subtitleFilter[item.field] += `${item.value}`;
        }
      });

      for (const key in subtitleFilter) {
        if (Object.hasOwnProperty.call(subtitleFilter, key)) {
          const itemSubtitle = subtitleFilter[key];
          subTitleArray.push({
            value: itemSubtitle,
          });
        }
      }
    }
    let response = await ApiHelpers.get(apiEndPoint, params);
    if (
      response.status === 200 &&
      response.results.data !== undefined &&
      response.results.data
    ) {
      fetchData = response.results.data;
      fetchData.map(async (item) => {
        summary.invoice_total += 1;
        summary.invoice_total_amount += Number(item.invoice_total_amount);
        summary.invoice_total_amount_reduce += Number(
          item.invoice_total_amount_reduce
        );
        item.detail_object.item.map((itemDetail, indexDetail) => {
          let newItem = {
            _refColConfig: "main",
            invoice_datetime: "",
            client_name: "",
            status: "",
            invoice_number: "",
            invoice_total_amount: "",
            invoice_total_amount_reduce: "",
            item_sku: "",
            item_name: "",
            item_unit: "",
            item_qty: 0,
            item_price: 0,
            item_price_payload: 0,
            item_tot_price: 0,
            item_tot_price_payload: 0,
          };

          // info transaksi
          if (indexDetail === 0) {
            const mergeEndRow = item.detail_object.item.length - 1;
            newItem._colConfig = {
              invoice_datetime: {
                merge: {
                  startRow: 0,
                  startCol: 1,
                  endRow: mergeEndRow,
                  endCol: 1,
                },
              },
              client_name: {
                merge: {
                  startRow: 0,
                  startCol: 2,
                  endRow: mergeEndRow,
                  endCol: 2,
                },
              },
              status: {
                merge: {
                  startRow: 0,
                  startCol: 3,
                  endRow: mergeEndRow,
                  endCol: 3,
                },
              },
              invoice_number: {
                merge: {
                  startRow: 0,
                  startCol: 4,
                  endRow: mergeEndRow,
                  endCol: 4,
                },
              },
              invoice_total_amount: {
                merge: {
                  startRow: 0,
                  startCol: 5,
                  endRow: mergeEndRow,
                  endCol: 5,
                },
              },
              invoice_total_amount_reduce: {
                merge: {
                  startRow: 0,
                  startCol: 6,
                  endRow: mergeEndRow,
                  endCol: 6,
                },
              },
            };
            newItem.invoice_datetime = new Date(item.invoice_datetime);
            newItem.client_name = item.client_name;
            newItem.status =
              item.status.charAt(0).toUpperCase() + item.status.slice(1);
            newItem.invoice_number = item.invoice_number;
            newItem.invoice_total_amount = Number(item.invoice_total_amount);
            newItem.invoice_total_amount_reduce = Number(
              item.invoice_total_amount_reduce
            );
          }

          // produk
          newItem.item_sku = itemDetail.sku;
          newItem.item_name = itemDetail.name;
          newItem.item_unit = itemDetail.uom;
          newItem.item_qty = Number(itemDetail.qty);
          newItem.item_price = Number(itemDetail.unit_price);
          newItem.item_price_payload = Number(
            item.payload_object.item[indexDetail].unit_price
          );
          newItem.item_tot_price = newItem.item_qty * newItem.item_price;
          newItem.item_tot_price_payload =
            newItem.item_qty * newItem.item_price_payload;
          // summary produk
          summary.item += 1;
          summary.item_qty += newItem.item_qty;
          summary.item_price += newItem.item_price;
          summary.item_price_payload += newItem.item_price_payload;
          summary.item_tot_price += newItem.item_tot_price;
          summary.item_tot_price_payload += newItem.item_tot_price_payload;

          processedData.push(newItem);
        });

        return item;
      });
    } else {
      showSnackbar = true;
      messageSnackbar = response.message || "Terjadi Kesalahan";
    }

    if (showSnackbar) {
      this.setState({
        severitySnackbar: "error",
        showSnackbar: true,
        messageSnackbar,
      });

      this.ref_LoadingDialog.onCloseDialog();
      return;
    }

    // console.log(processedData);
    // return;
    let dataExport = {
      filename: `Data Transaksi ${moment().format('YYYYMMDDHHmmss')}` ,
      title: `Data Transaksi ${moment().format('YYYYMMDDHHmmss')}` ,
      subTitleArray,
      config: [
        {
          header: "Tanggal Transaksi",
          key: "invoice_datetime",
          width: 28,
        },
        {
          header: "Nama Merchant",
          key: "client_name",
        },
        {
          header: "Status Sync",
          key: "status",
        },
        {
          header: "Nomor Invoice",
          key: "invoice_number",
        },
        {
          header: "Nominal Transaksi",
          key: "invoice_total_amount",
        },
        {
          header: "Nominal Sync",
          key: "invoice_total_amount_reduce",
        },

        // item
        {
          header: "SKU Produk",
          key: "item_sku",
        },
        {
          header: "Nama Produk",
          key: "item_name",
        },
        {
          header: "Qty Produk",
          key: "item_qty",
        },
        {
          header: "Unit Produk",
          key: "item_unit",
        },
        {
          header: "Harga Produk",
          key: "item_price",
        },
        {
          header: "Harga Sync Produk",
          key: "item_price_payload",
        },
        {
          header: "Total Harga Produk",
          key: "item_tot_price",
        },
        {
          header: "Total Harga Sync Produk",
          key: "item_tot_price_payload",
        },
      ],
      headerConfig: {
        _global: {
          style: {
            font: {
              size: 12,
              bold: true,
            },
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: {
                argb: "DEDEDE",
              },
            },
            alignment: {
              vertical: "top",
              horizontal: "center",
              wrapText: true,
            },
            border: {
              top: {
                style: "thin",
              },
              left: {
                style: "thin",
              },
              bottom: {
                style: "thin",
              },
              right: {
                style: "thin",
              },
            },
          },
        },
      },
      refColConfigInData: {
        main: {
          _global: {
            style: {
              font: {
                size: 11,
              },
              alignment: {
                vertical: "top",
                horizontal: "left",
                wrapText: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
            },
          },
          invoice_datetime: {
            style: {
              font: {
                size: 11,
                bold: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
              alignment: {
                vertical: "top",
                horizontal: "center",
                wrapText: true,
              },
              numFmt: "dd/mm/yyyy hh:mm",
            },
          },
          status: {
            style: {
              font: {
                size: 11,
              },
              alignment: {
                vertical: "top",
                horizontal: "center",
                wrapText: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
            },
          },
          invoice_number: {
            style: {
              font: {
                size: 11,
              },
              alignment: {
                vertical: "top",
                horizontal: "center",
                wrapText: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
            },
          },
          invoice_total_amount: {
            style: {
              font: {
                size: 11,
              },
              alignment: {
                vertical: "top",
                horizontal: "right",
                wrapText: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
              numFmt: EXPORT_EXCEL_STYLE_NUM_FMT.currencyRp,
            },
          },
          invoice_total_amount_reduce: {
            style: {
              font: {
                size: 11,
              },
              alignment: {
                vertical: "top",
                horizontal: "right",
                wrapText: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
              numFmt: EXPORT_EXCEL_STYLE_NUM_FMT.currencyRp,
            },
          },
          item_sku: {
            style: {
              font: {
                size: 11,
              },
              alignment: {
                vertical: "top",
                horizontal: "center",
                wrapText: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
            },
          },
          item_qty: {
            style: {
              font: {
                size: 11,
              },
              alignment: {
                vertical: "top",
                horizontal: "right",
                wrapText: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
              numFmt: EXPORT_EXCEL_STYLE_NUM_FMT.number,
            },
          },
          item_unit: {
            style: {
              font: {
                size: 11,
              },
              alignment: {
                vertical: "top",
                horizontal: "center",
                wrapText: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
            },
          },
          item_price: {
            style: {
              font: {
                size: 11,
              },
              alignment: {
                vertical: "top",
                horizontal: "right",
                wrapText: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
              numFmt: EXPORT_EXCEL_STYLE_NUM_FMT.currencyRp,
            },
          },
          item_price_payload: {
            style: {
              font: {
                size: 11,
              },
              alignment: {
                vertical: "top",
                horizontal: "right",
                wrapText: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
              numFmt: EXPORT_EXCEL_STYLE_NUM_FMT.currencyRp,
            },
          },
          item_tot_price: {
            style: {
              font: {
                size: 11,
              },
              alignment: {
                vertical: "top",
                horizontal: "right",
                wrapText: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
              numFmt: EXPORT_EXCEL_STYLE_NUM_FMT.currencyRp,
            },
          },
          item_tot_price_payload: {
            style: {
              font: {
                size: 11,
              },
              alignment: {
                vertical: "top",
                horizontal: "right",
                wrapText: true,
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
              numFmt: EXPORT_EXCEL_STYLE_NUM_FMT.currencyRp,
            },
          },
        },
        summary: {
          _global: {
            style: {
              font: {
                size: 12,
                bold: true,
              },
              alignment: {
                vertical: "top",
                horizontal: "center",
                wrapText: true,
              },
              fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                  argb: "BFBFBF",
                },
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
            },
          },
          currency: {
            style: {
              font: {
                size: 12,
                bold: true,
              },
              alignment: {
                vertical: "top",
                horizontal: "right",
                wrapText: true,
              },
              fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                  argb: "BFBFBF",
                },
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
              numFmt: EXPORT_EXCEL_STYLE_NUM_FMT.currencyRp,
            },
          },
          number: {
            style: {
              font: {
                size: 12,
                bold: true,
              },
              alignment: {
                vertical: "top",
                horizontal: "right",
                wrapText: true,
              },
              fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                  argb: "BFBFBF",
                },
              },
              border: {
                top: {
                  style: "thin",
                },
                left: {
                  style: "thin",
                },
                bottom: {
                  style: "thin",
                },
                right: {
                  style: "thin",
                },
              },
              numFmt: EXPORT_EXCEL_STYLE_NUM_FMT.number,
            },
          },
        },
      },
      data: processedData,
      summary: [
        {
          _refColConfig: "summary",
          merge: {
            startCol: 1,
            endCol: 4,
          },
          value: `Ringkasan ${CommonHelper.formatNumber(
            summary.invoice_total
          )} Transaksi`,
        },
        // TOT Nominal Transaksi
        {
          _refColConfig: "summary",
          keyConfig: "currency",
          value: summary.invoice_total_amount,
        },
        // TOT Nominal Sync
        {
          _refColConfig: "summary",
          keyConfig: "currency",
          value: summary.invoice_total_amount_reduce,
        },
        {
          _refColConfig: "summary",
          merge: {
            startCol: 7,
            endCol: 8,
          },
          value: `${CommonHelper.formatNumber(summary.item)} Produk`,
        },
        // TOT Item QTY
        {
          _refColConfig: "summary",
          keyConfig: "number",
          value: summary.item_qty,
        },
        {
          _refColConfig: "summary",
          value: "",
        },
        // TOT Item Price
        {
          _refColConfig: "summary",
          keyConfig: "currency",
          value: summary.item_price,
        },
        // TOT Item Price Sync
        {
          _refColConfig: "summary",
          keyConfig: "currency",
          value: summary.item_price_payload,
        },
        // TOT Qty x Item Price
        {
          _refColConfig: "summary",
          keyConfig: "currency",
          value: summary.item_tot_price,
        },
        // TOT Qty x Item Price Sync
        {
          _refColConfig: "summary",
          keyConfig: "currency",
          value: summary.item_tot_price_payload,
        },
      ],
    };

    const exportExcel = new ExportExcel(dataExport);
    exportExcel.downloadFile();

    this.ref_LoadingDialog.onCloseDialog();
  };
  // ====================================================================================
  // ========== RENDER SECTION ==========================================================
  // ====================================================================================
  render() {
    return (
      <PageLayout>
        <MyTable
          ref={(value) => (this.ref_MyTableCustom = value)}
          title={`${this.state.pageLabel}`}
          arrHeading={ARR_TABLE_HEADER}
          dataTable={this.state.fetchData}
          isFetched={this.state.isFetched}
          isLoadPage={this.state.isLoadPage}
          inputFilter={this.state.inputFilter}
          fetchDataPagination={this.state.fetchDataPagination}
          onPageListeners={(fetchDataPage) => {
            this.setState({ fetchDataPage, isLoadPage: true }, () =>
              this.fetchData(false)
            );
          }}
          onSort={(inputSort) => {
            this.setState(
              { inputSort, isLoadPage: true, fetchDataPage: 1 },
              () => this.fetchData(false)
            );
          }}
          renderItems={(item, index) => this.renderItems(item, index)}
          inputSearch={this.state.inputSearch}
          placeholderSearch={`Tuliskan disini cari ${this.state.pageLabel.toLowerCase()}...`}
          onSearch={(inputSearch) => {
            this.ref_MyTableCustom.setSearchValue("");
            this.setState({ inputSearch }, this.onRefresh);
          }}
          onClearSearch={() => {
            this.ref_MyTableCustom.setSearchValue("");
            this.setState({ inputSearch: "" }, this.onRefresh);
          }}
          showFilter
          onFilter={() => {
            this.ref_ModalSyncDataFilter.onShowDialog(this.state.inputFilter);
          }}
          onRemoveFilterItem={(index) => {
            let inputFilter = this.state.inputFilter;
            inputFilter.splice(index, 1);
            this.setState({ inputFilter }, this.onRefresh);
          }}
          showDownload
          onDownload={() => {
            this.onDownloadFileLocalExcel();
          }}
          onLoadMore={() => {
            this.onLoadMore();
          }}
          onReload={() => {
            this.onRefresh();
          }}
        />

        {/* LOADING DIALOG */}
        <Loading ref={(value) => (this.ref_LoadingDialog = value)} />

        {/* SNACKBAR & ALERT DIALOG */}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          style={{ bottom: 80 }}
          open={this.state.showSnackbar}
          autoHideDuration={4600}
          onClose={() => {
            this.setState({ showSnackbar: false, messageSnackbar: "" });
          }}
        >
          <Alert
            severity={this.state.severitySnackbar}
            onClose={() => {
              this.setState({ showSnackbar: false, messageSnackbar: "" });
            }}
            sx={{ width: "100%" }}
          >
            {this.state.messageSnackbar}
          </Alert>
        </Snackbar>

        <ModalSyncDataDetail
          ref={(val) => (this.ref_ModalSyncDataDetail = val)}
        />

        <ModalSyncDataFilter
          ref={(val) => (this.ref_ModalSyncDataFilter = val)}
          onSubmit={(inputFilter) => {
            this.setState({ inputFilter }, this.onRefresh);
            this.ref_ModalSyncDataFilter.onCloseDialog();
          }}
        />
      </PageLayout>
    );
  }

  renderItems(item, index) {
    let backgroundColor = "rgba(230, 126, 34, 0.25)";
    let color = "rgba(230, 126, 34, 1)";
    if (item.client_status === "done") {
      backgroundColor = "rgba(22, 160, 133, 0.25)";
      color = "rgba(22, 160, 133, 1)";
    }
    if (item.client_status === "failed") {
      backgroundColor = "rgba(192, 57, 43, 0.25)";
      color = "rgba(192, 57, 43, 1)";
    }
    return (
      <tr key={index}>
        <td>
          <div className={"list_item text_align_left"}>
            <div className={"title"}>Nama Merchant:</div>
            <div className={"value"}>{item.client_name}</div>
          </div>
        </td>
        <td>
          <div className={`${"list_item"}`}>
            <div className={"title"}>Status Sync:</div>
            <div
              className={"value"}
              style={{ textTransform: "uppercase", fontSize: 11 }}
            >
              <span
                style={{
                  backgroundColor,
                  color,
                  fontWeight: "bold",
                  padding: "5px 10px",
                  borderRadius: 20,
                }}
              >
                {item.client_status}
              </span>
            </div>
          </div>
        </td>
        <td>
          <div className={"list_item text_align_left"}>
            <div className={"title"}>Nomor Invoice:</div>
            <div className={"value"}>{item.invoice_number}</div>
          </div>
        </td>
        <td>
          <div className={"list_item text_align_left"}>
            <div className={"title"}>Tipe:</div>
            <div className={"value"}>{item.type}</div>
          </div>
        </td>
        <td>
          <div className={"list_item text_align_right"}>
            <div className={"title"}>Tanggal Transaksi:</div>
            <div className={"value"}>
              {item.invoice_datetime !== ""
                ? moment(item.invoice_datetime).format("lll")
                : "-"}
            </div>
          </div>
        </td>
        <td>
          <div className={`${"list_item text_align_right"}`}>
            <div className={"title"}>Nominal Transaksi:</div>
            <div className={"value"}>
              {CommonHelper.formatNumber(item.invoice_total_amount, "idr")}
            </div>
          </div>
        </td>
        <td>
          <div className={`${"list_item text_align_right"}`}>
            <div className={"title"}>Nominal Transaksi Sync:</div>
            <div className={"value"}>
              {CommonHelper.formatNumber(
                item.invoice_total_amount_reduce,
                "idr"
              )}
            </div>
          </div>
        </td>
        <td className="fixed_position">
          <div className={`${"list_button"}`}>
            <Tooltip title="Lihat Detail Merchant">
              <IconButton
                aria-label="detail"
                onClick={() => {
                  this.onClickDetailListener(item, index);
                }}
              >
                <Icon style={{ fontSize: 22, color: "#999" }}>
                  info_outlined
                </Icon>
              </IconButton>
            </Tooltip>
          </div>
        </td>
      </tr>
    );
  }
}
