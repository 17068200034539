import React from "react"
import CommonHelper from "utils/CommonHelper"
import { Box, Icon, Modal } from "@mui/material"
import moment from "moment"
import Highlight from "react-highlight"

export default class ModalSyncDataDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      dataParse: null,
    }
    this.firstMount = true
  }

  componentDidMount() {
    if (this.firstMount) {
      this.firstMount = false
    }
  }

  componentWillUnmount() {}

  // ====================================================================================
  // ========== INITIALIZE, GET DATA ====================================================
  // ====================================================================================

  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================
  onShowDialog = (dataParse) => {
    this.setState({ showModal: true, dataParse })
  }
  onCloseDialog = () => {
    this.setState({ showModal: false, dataParse: null })
  }
  // ====================================================================================
  // ========== RENDER SECTION ==========================================================
  // ====================================================================================
  render() {
    return (
      <Modal open={this.state.showModal} onClose={this.onCloseDialog}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100wh",
            height: "100vh",
          }}
        >
          <div className={`my_modal_detail_container`}>
            <div className={"toolbar"}>
              <div className={"title"}>Detail Data Webhook</div>
              <div className={"close"} onClick={this.onCloseDialog}>
                <Icon color="primary">close</Icon>
              </div>
            </div>
            <div className={"content"}>
              <div className={"flex"}>
                <div className={"left"}>{this.renderLeftContent()}</div>
                <div className={"right"}>{this.renderRightContent()}</div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    )
  }

  renderLeftContent() {
    let objData = this.state.dataParse
    if (objData !== null) {
      let backgroundColor = 'rgba(230, 126, 34, 0.25)'
      let color = 'rgba(230, 126, 34, 1)'
      if(objData.client_status == 'done'){
        backgroundColor = 'rgba(22, 160, 133, 0.25)'
        color = 'rgba(22, 160, 133, 1)'
      }
      if(objData.client_status == 'failed'){
        backgroundColor = 'rgba(192, 57, 43, 0.25)'
        color = 'rgba(192, 57, 43, 1)'
      }
      return (
        <>
          <h5
            style={{
              marginBottom: 20,
              textAlign: "center",
              backgroundColor: "rgba(0,0,0,0.1)",
              borderRadius: 5,
              padding: "10px 0",
              textTransform: "uppercase",
            }}
          >
            Informasi Umum
          </h5>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Nama Merchant</div>
            <div className={"label_right"}>{objData.client_name}</div>
          </div>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Nomor Invoice</div>
            <div className={"label_right"}>{objData.invoice_number}</div>
          </div>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Tipe</div>
            <div className={"label_right"}>{objData.type}</div>
          </div>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Tanggal Transaksi</div>
            <div className={"label_right"}>
              {moment(objData.invoice_datetime).format("lll")}
            </div>
          </div>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Nominal Transaksi</div>
            <div className={"label_right"}>
              {CommonHelper.formatNumber(objData.invoice_total_amount, "idr")}
            </div>
          </div>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Persen Pengurang Nilai Transaksi</div>
            <div className={"label_right"}>
              {objData.invoice_reduce_percent}%
            </div>
          </div>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Nominal Transaksi yang di Sync</div>
            <div className={"label_right"}>
              {CommonHelper.formatNumber(
                objData.invoice_total_amount_reduce,
                "idr"
              )}
            </div>
          </div>
          <div
            className={"label_flex_row"}
            style={{ ...inlineStyle.rows, borderBottom: "none" }}
          >
            <div>Status Sync</div>
            <div>
              <span
                style={{
                  backgroundColor,
                  color,
                  fontWeight: "bold",
                  padding: "5px 10px",
                  borderRadius: 20,
                  fontSize: 13,
                  textTransform: "uppercase",
                }}
              >
                {objData.client_status}
              </span>
            </div>
          </div>
          {objData.detail_data !== '' && 
            <>
              <h5
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  textAlign: "center",
                  backgroundColor: "rgba(0,0,0,0.1)",
                  borderRadius: 5,
                  padding: "10px 0",
                  textTransform: "uppercase",
                }}
              >
                Data Dari Accurate
              </h5>
              <div>
                <Highlight className="json">
                  {JSON.stringify(JSON.parse(objData.detail_data), null, 2)}
                </Highlight>
              </div>
            </>
          }
        </>
      )
    }
  }
  renderRightContent(){
    let objData = this.state.dataParse
    if(objData !== null){
      return (
        <>
        {objData.payload !== '' &&
          <>
            <h5
              style={{
                marginBottom: 20,
                textAlign: "center",
                backgroundColor: "rgba(0,0,0,0.1)",
                borderRadius: 5,
                padding: "10px 0",
                textTransform: "uppercase",
              }}
            >
              Data yang di Sync
            </h5>
            <div>
              <Highlight className="json">
                {JSON.stringify(JSON.parse(objData.payload), null, 2)}
              </Highlight>
            </div>
          </>
        }
        {objData.client_response !== '' &&
          <>
            <h5
              style={{
                marginTop: 30,
                marginBottom: 20,
                textAlign: "center",
                backgroundColor: "rgba(0,0,0,0.1)",
                borderRadius: 5,
                padding: "10px 0",
                textTransform: "uppercase",
              }}
            >
              Balikan Data Saat Sync
            </h5>
            <div>
              <Highlight className="json">
                {JSON.stringify(JSON.parse(objData.client_response), null, 2)}
              </Highlight>
            </div>
          </>
        }
        </>
      )
    }
  }
}

const inlineStyle = {
  rows: {
    fontSize: 15,
    borderBottom: "solid 1px rgba(0,0,0,0.1)",
    paddingBottom: 12,
    marginBottom: 12,
  },
}