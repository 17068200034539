/*
Created by esoda
Created on Dec, 2023
Contact esoda.id
Last modified: -
*/

import React from 'react'
import { Modal as MuiModal, Box, IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment, Button, Icon } from '@mui/material'
import moment from 'moment'
import DatePicker from './DatePicker'
import CommonHelper from 'utils/CommonHelper'

const YESTERDAY = moment().subtract(1, 'days').format("YYYY-MM-DD")
const CURRENTDATE = moment()
const THIS_WEEK_START_DATE = CURRENTDATE.clone().startOf('isoWeek').format("YYYY-MM-DD")
const THIS_WEEK_END_DATE = CURRENTDATE.clone().endOf('isoWeek').format("YYYY-MM-DD")
const MONTH = moment().format('MM')
const YEAR = moment().format('YYYY')
const DATERANGEINMONTH = CommonHelper.getDateRangeInMonth(MONTH, YEAR)
const THIS_MONTH_START_DATE = DATERANGEINMONTH.startDate
const THIS_MONTH_END_DATE  = DATERANGEINMONTH.endDate
const PREV_MONTH = moment().subtract(1, 'months').format("MM")
const PREV_YEAR = moment().subtract(1, 'months').format("YYYY")
const DATERANGEPREVMONTH = CommonHelper.getDateRangeInMonth(PREV_MONTH, PREV_YEAR)
const PREV_MONTH_START_DATE = DATERANGEPREVMONTH.startDate
const PREV_MONTH_END_DATE  = DATERANGEPREVMONTH.endDate
const LAST_WEEK_START_DATE = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')
const LAST_WEEK_END_DATE = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
const LAST_7_DAY_END_DATE = moment().subtract(7,'d').format('YYYY-MM-DD');
const LAST_30_DAY_END_DATE = moment().subtract(30,'d').format('YYYY-MM-DD');

const ARR_DATE = [
    {
        title:"Semua tanggal",
        start_date:"",
        end_date:"",
        selected:false
    },
    {
        title:"Rentang tanggal",
        start_date:"",
        end_date:"",
        selected:false
    },
    {
        title:"Kemarin",
        start_date:YESTERDAY,
        end_date:YESTERDAY,
        selected:false
    },
    {
        title:"Hari ini",
        start_date:moment().format("YYYY-MM-DD"),
        end_date:moment().format("YYYY-MM-DD"),
        selected:false
    },
    {
        title:"Minggu ini",
        start_date:THIS_WEEK_START_DATE,
        end_date:THIS_WEEK_END_DATE,
        selected:false
    },
    {
        title:"Minggu lalu",
        start_date:LAST_WEEK_START_DATE,
        end_date:LAST_WEEK_END_DATE,
        selected:false
    },
    {
        title:"7 hari terakhir",
        start_date:LAST_7_DAY_END_DATE,
        end_date:moment().format("YYYY-MM-DD"),
        selected:false
    },
    {
        title:"30 hari terakhir",
        start_date:LAST_30_DAY_END_DATE,
        end_date:moment().format("YYYY-MM-DD"),
        selected:false
    },
    {
        title:"Bulan ini",
        start_date:THIS_MONTH_START_DATE,
        end_date:THIS_MONTH_END_DATE,
        selected:false
    },
    {
        title:"Bulan lalu",
        start_date:PREV_MONTH_START_DATE,
        end_date:PREV_MONTH_END_DATE,
        selected:false
    },
]

const ARR_DATE_1 = [
    {
        title:"Rentang tanggal",
        start_date:"",
        end_date:"",
        selected:false
    },
    {
        title:"Kemarin",
        start_date:YESTERDAY,
        end_date:YESTERDAY,
        selected:false
    },
    {
        title:"Hari ini",
        start_date:moment().format("YYYY-MM-DD"),
        end_date:moment().format("YYYY-MM-DD"),
        selected:false
    },
    {
        title:"Minggu ini",
        start_date:THIS_WEEK_START_DATE,
        end_date:THIS_WEEK_END_DATE,
        selected:false
    },
    {
        title:"Minggu lalu",
        start_date:LAST_WEEK_START_DATE,
        end_date:LAST_WEEK_END_DATE,
        selected:false
    },
    {
        title:"7 hari terakhir",
        start_date:LAST_7_DAY_END_DATE,
        end_date:moment().format("YYYY-MM-DD"),
        selected:false
    },
    {
        title:"30 hari terakhir",
        start_date:LAST_30_DAY_END_DATE,
        end_date:moment().format("YYYY-MM-DD"),
        selected:false
    },
    {
        title:"Bulan ini",
        start_date:THIS_MONTH_START_DATE,
        end_date:THIS_MONTH_END_DATE,
        selected:false
    },
    {
        title:"Bulan lalu",
        start_date:PREV_MONTH_START_DATE,
        end_date:PREV_MONTH_END_DATE,
        selected:false
    },
]

export default class DateFilter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowModal: false,
            arrData: [],
            inputs: { start_date:"", end_date:"" },
            errors: {},
            selectedDateParams:""
        }
    }

    // ====================================================================================
    // ========== INITIALIZE, GET DATA ====================================================
    // ====================================================================================
    onSetData = (selectedTitle = "", start_date = "", end_date = "") => {
        let DATA_ARRAY = ARR_DATE
        if(this.props.hiddenAllDate !== undefined) {
            DATA_ARRAY = ARR_DATE_1
        }
        DATA_ARRAY.map((item) => {
            item.selected = false
            if(item.title === "Rentang tanggal") {
                item.start_date = start_date
                item.end_date = end_date
            }
            if(item.title === "Semua tanggal") {
                item.start_date = ""
                item.end_date = ""
            }
            if(item.title === selectedTitle) {
                item.selected = true
            }
            return item
        })
        this.setState({ 
            arrData: DATA_ARRAY,
            inputs: { 
                start_date:selectedTitle === "Rentang tanggal" ? start_date : "", 
                end_date:selectedTitle === "Rentang tanggal" ? end_date : "" 
            },
            errors: {},
        }, this.onShowDialog)
    }
    getDateValue = (start, end) => {
        let dateValue = "----"
        if(start !== "") {
            dateValue = `${moment(start).format("LL")}`
            if(end !== "") {
                if(start !== end) {
                    dateValue = `${moment(start).format("LL")} s.d. ${moment(end).format("LL")}`
                }
            }
        }

        return dateValue
    }

    // ====================================================================================
    // ========== ACTION LISTENERS ========================================================
    // ====================================================================================
    onShowDialog = () => { this.setState({ isShowModal:true }) }
    onCloseDialog = () => { this.setState({ isShowModal:false }) }
    onTextInputListeners = (text, input) => {
        let inputs = this.state.inputs
        inputs[input] = text
        this.setState(prevState => ({ ...prevState, inputs }))
    }
    onTextErrorListeners = (error, input) => {
        let errors = this.state.errors
        errors[input] = error
        this.setState(prevState => ({ ...prevState, errors }))
    }
    onValidateInputListeners = () => {
        let isValid = true

        if (!this.state.inputs.start_date) {
            this.onTextErrorListeners('Harus diisi', 'start_date');
            isValid = false
        }
        if (!this.state.inputs.end_date) {
            this.onTextErrorListeners('Harus diisi', 'end_date');
            isValid = false
        }

        if (isValid) {
            const { onSelect } = this.props
            if(onSelect !== undefined) {
                let params = {
                    title:"Rentang tanggal",
                    start_date:this.state.inputs.start_date,
                    end_date:this.state.inputs.end_date,
                    selected:true
                }
                onSelect(params)
                this.onCloseDialog()
            }
        }
    }
    onSelectedItemListeners = (item, index) => {
        const { onSelect } = this.props
        let arrData = this.state.arrData
        arrData.map((itm, idx) => {
            itm.selected = false
            if(idx === index) { itm.selected = true }
            return itm
        })
        if(item.title !== "Rentang tanggal") {
            if(onSelect !== undefined) {
                onSelect(item)
                this.onCloseDialog()
            }
        }
        this.setState({ arrData })
    }
    onDateChangeListeners = (value, params) => {
        if(params === "start_date") {
            this.onTextInputListeners(value, params)
        } else {
            let isAfter = moment(value).isAfter(this.state.inputs.start_date)
            if(isAfter) {
                this.onTextInputListeners(value, params)
            } else {
                let isSame = moment(value).isSame(this.state.inputs.start_date)
                if(isSame) {
                    this.onTextInputListeners(value, params)
                } else {
                    this.onTextErrorListeners('Minimal sama dgn tgl mulai', 'end_date');
                }
            }
        }
        this.setState({ selectedDateParams:"" })
    }

    // ====================================================================================
    // ========== RENDER SECTIONS =========================================================
    // ====================================================================================
    render() {
        return(
            <>
                <MuiModal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.isShowModal}
                    onClose={() => {this.onCloseDialog()}}
                    >
                    <Box sx={{
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        width: '100wh',
                        height: '100vh',
                        }}>
                        <div className={`my_modal_container`}>
                            <Box 
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    px: 8,
                                    py: 8,
                                    borderBottom:`solid 1px var(--primary-color)`
                                }}>
                                <div style={{color:'var(--primary-color)', fontWeight:"bold"}}>Pilih Tanggal</div>
                                <IconButton onClick={() => {this.onCloseDialog()}} color="primary"
                                    edge="end"
                                    >
                                    <Icon style={{ color:'var(--primary-color)' }}>close</Icon>
                                </IconButton>
                            </Box>
                            <Box sx={{
                                px: 8,
                                py: 16,
                                maxHeight: 'calc(100vh - 215px)',
                                overflow: 'auto',
                                }}>
                                {this.renderContents()}
                            </Box>
                        </div>
                    </Box>
                </MuiModal>
                <DatePicker 
                    ref={(value) => this.ref_DatePicker = value}
                    onSelect={(date) => {
                        let inputDate = moment(date).format("YYYY-MM-DD")
                        this.onDateChangeListeners(inputDate, this.state.selectedDateParams)
                    }}
                />
            </>
        )
    }
    renderContents() {
        let arrData = this.state.arrData
        if(arrData.length > 0) {
            return(
                arrData.map((item, index) => {
                    return(
                        <div key={index}
                            style={{
                                cursor:"pointer",
                                borderTop:index > 0 ? "solid 1px rgba(0,0,0,0.1)" : "none",
                                paddingTop:index > 0 ? 15 : 5,
                                paddingBottom:15,
                                color:"#505050",
                                fontSize:14,
                                fontWeight:item.selected ? "bold" : "normal",
                            }}
                            onClick={() => { this.onSelectedItemListeners(item, index) }}
                            >
                            <div style={{display:"flex",
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",}}
                                >
                                <div style={{ flex:1, paddingRight:15 }}>
                                    <div style={{
                                        color:item.selected ? 'var(--primary-color)' : "#444",
                                        }}>
                                        {item.title}
                                    </div>
                                    {item.title !== "Rentang tanggal" && item.title !== "Semua tanggal" &&
                                        <div style={{
                                            fontSize:12,
                                            color:item.selected ? "#444" : "#999",
                                            marginTop:3,
                                            }}>
                                            {this.getDateValue(item.start_date, item.end_date)}
                                        </div>
                                    }
                                </div>
                                {item.selected ?
                                    <Icon style={{ color:'var(--primary-color)' }}>radio_button_checked</Icon>
                                    :
                                    <Icon style={{ color:"rgba(0,0,0,0.2)" }}>radio_button_unchecked</Icon>
                                }
                            </div>
                            {item.title === "Rentang tanggal" && item.selected && 
                                <>
                                    <div style={{
                                        display:"flex",
                                        flexDirection:"row", 
                                        alignItems:"flex-start", 
                                        justifyContent:"center", 
                                        paddingTop:25, 
                                        paddingBottom:10,
                                        paddingRight:3,
                                        }}>
                                        <FormControl className="font_family" fullWidth variant="outlined" style={{ marginRight:7.5 }}>
                                            <InputLabel className="font_family" htmlFor="input-text-start-date" style={{ fontSize:13 }}>Tanggal Mulai</InputLabel>
                                            <OutlinedInput
                                                style={{ fontSize:13 }}
                                                id="input-text-start-date"
                                                className="font_family"
                                                startAdornment={<InputAdornment position="start"><Icon style={{ fontSize:13 }}>event_available</Icon></InputAdornment>}
                                                endAdornment={<InputAdornment position="end"><Icon>arrow_drop_down</Icon></InputAdornment>}
                                                label="Tanggal Mulai"
                                                placeholder='Pilih tanggal mulai'
                                                value={this.state.inputs.start_date !== "" ? moment(this.state.inputs.start_date).format("ll") : ""}
                                                onClick={() => {
                                                    this.setState({ selectedDateParams:"start_date" })
                                                    this.onTextErrorListeners(null, 'start_date')
                                                    let date = this.state.inputs.start_date !== "" ? this.state.inputs.start_date : moment().format("YYYY-MM-DD")
                                                    this.ref_DatePicker.setData(date)
                                                }}
                                                error={this.state.errors.start_date !== undefined && this.state.errors.start_date !== null ? true : false}
                                            />
                                            <div style={{
                                                marginTop: 5, display: "flex", flexDirection: "row", justifyContent: "space-between",
                                                paddingLeft: 5, paddingRight: 5
                                                }}>
                                                <span style={{
                                                    flex:1,
                                                    paddingRight:20,
                                                    color: '#e74c3c',
                                                    fontSize:10
                                                }}>Wajib</span>
                                                {this.state.errors.start_date !== undefined && this.state.errors.start_date !== null &&
                                                    <span style={{
                                                        textAlign: "right",
                                                        color: "red",
                                                        fontSize:10
                                                    }} className="font_family">{this.state.errors.start_date}</span>
                                                }
                                            </div>
                                        </FormControl>

                                        <FormControl className="font_family" fullWidth variant="outlined" style={{ marginLeft:7.5 }}>
                                            <InputLabel className="font_family" htmlFor="input-text-end-date" style={{ fontSize: 13 }}>Tanggal akhir</InputLabel>
                                            <OutlinedInput
                                                style={{ fontSize:13 }}
                                                id="input-text-end-date"
                                                className="font_family"
                                                startAdornment={<InputAdornment position="start"><Icon style={{ fontSize:13 }}>event_busy</Icon></InputAdornment>}
                                                endAdornment={<InputAdornment position="end"><Icon>arrow_drop_down</Icon></InputAdornment>}
                                                label="Tanggal Akhir"
                                                placeholder='Pilih tanggal akhir'
                                                value={this.state.inputs.end_date !== "" ? moment(this.state.inputs.end_date).format("ll") : ""}
                                                onClick={() => {
                                                    this.setState({ selectedDateParams:"end_date" })
                                                    this.onTextErrorListeners(null, 'end_date')
                                                    let date = this.state.inputs.end_date !== "" ? this.state.inputs.end_date : moment().format("YYYY-MM-DD")
                                                    this.ref_DatePicker.setData(date)
                                                }}
                                                error={this.state.errors.end_date !== undefined && this.state.errors.end_date !== null ? true : false}
                                            />
                                            <div style={{
                                                marginTop: 5, display: "flex", flexDirection: "row", justifyContent: "space-between",
                                                paddingLeft: 5, paddingRight: 5
                                                }}>
                                                <span style={{
                                                    flex:1,
                                                    paddingRight:20,
                                                    color:'#e74c3c',
                                                    fontSize:10
                                                }}>Wajib</span>
                                                {this.state.errors.end_date !== undefined && this.state.errors.end_date !== null &&
                                                    <span style={{
                                                        textAlign: "right",
                                                        color: "red",
                                                        fontSize:10
                                                    }} className="font_family">{this.state.errors.end_date}</span>
                                                }
                                            </div>
                                        </FormControl>
                                    </div>
                                    <div style={{marginTop:10, display:"flex", flexDirection:"row", marginBottom:10}}>
                                        <Button className={"font_family"}
                                            style={{ backgroundColor: 'var(--primary-color)', fontWeight: "bold", fontSize: 14}}
                                            sx={{ height: 38.5 }}
                                            variant="contained"
                                            onClick={() => { this.onValidateInputListeners() }}>
                                            <Icon style={{ fontSize: 16, marginRight: 5 }} >check_circle</Icon> Terapkan
                                        </Button>
                                        <Button className={"font_family"}
                                            style={{ fontWeight: "bold", fontSize: 14, marginLeft:15, color:'var(--primary-color)', 
                                                borderColor:'var(--primary-color)'
                                            }}
                                            sx={{ height: 38.5 }}
                                            variant="outlined"
                                            onClick={() => {
                                                let inputs = this.state.inputs
                                                inputs.start_date = ""
                                                inputs.end_date = ""
                                                this.setState({ inputs })
                                                this.onTextErrorListeners(null, 'start_date')
                                                this.onTextErrorListeners(null, 'end_date')
                                            }}>
                                            <Icon style={{ fontSize: 16, marginRight: 5, color:'var(--primary-color)' }} >restart_alt</Icon> Reset
                                        </Button>
                                    </div>
                                </>
                            }
                        </div>
                    )
                })
            )
        }
    }
}
