import React from "react";

export default class AccountProfile extends React.Component {
    render(){
        return (
            <div>
                <p>Halaman Profile</p>
            </div>
        )
    }
}