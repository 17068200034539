/*
Created by esoda
Created on Dec, 2023
Contact esoda.id
Last modified: -
*/

import React from 'react'
import { Box, IconButton, Dialog, Icon } from '@mui/material'
import moment from 'moment'

const fontFamilyBold = "bold"
const fontFamilyRegular = "normal";
const NOTE_COLOR = "rgba(80,80,80,0.7)"
const DEFAULT_FT_COLOR = "#505050"
const ITEM_COLOR = "#505050"
const ITEM_SELECTED_COLOR = "#fff"
const ITEM_BG_COLOR = "rgba(255,255,255,0.0)"
const CURRENT_COLOR_ACTIVE = "#FFFFFF"
const NAV_INACTIVE_COLOR = "rgba(0,0,0,0.2)"
const ARR_MONTH = [
    {"value":"01", "title":"Januari"},
    {"value":"02", "title":"Februari"},
    {"value":"03", "title":"Maret"},
    {"value":"04", "title":"April"},
    {"value":"05", "title":"Mei"},
    {"value":"06", "title":"Juni"},
    {"value":"07", "title":"Juli"},
    {"value":"08", "title":"Agustus"},
    {"value":"09", "title":"September"},
    {"value":"10", "title":"Oktober"},
    {"value":"11", "title":"November"},
    {"value":"12", "title":"Desember"},
]

export default class DatePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            isShowModal: false,
            showMode:"date",
            month: moment().format("MM"),
            year: moment().format("YYYY"),
            date: moment().format("DD"),
            selectedDate: moment().format("D"),
            arrDate: [],
            arrYears: [],
        }
    }

    // ====================================================================================
    // ========== INITIALIZE, GET DATA ====================================================
    // ====================================================================================
    setData = (params) => {
        this.setState({
            selectedDate:params, 
            showMode:"date",
            arrYears: [],
        })
        setTimeout(() => {
            this.generateDate(params)
            this.onShowDialog()
        }, 10)
    }

    // ====================================================================================
    // ========== ACTION LISTENERS ========================================================
    // ====================================================================================
    onShowDialog = () => { this.setState({ isShowModal: true }) }
    onCloseDialog = () => { this.setState({ isShowModal: false }) }

    // ====================================================================================
    // ========== DATE LISTENER SECTIONS ==================================================
    // ====================================================================================
    generateDate = (date = '') => {
        let arrDate = ['Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab', 'Min']
        let year = ''
        let month = ''
        if(date === ''){
            date = moment().format('YYYY-MM-01')
        } else {
            date = moment(date).startOf('month').format('YYYY-MM-DD')
        }
        year = moment(date).format('YYYY')
        month = moment(date).format('MM')
        let firstDay = Number(moment(date).format('d'))
        let endDate = new Date(year, month, 0).getDate()
        let startDate = 1
        for (let i = 1; i <= 42; i++) {
            if (i >= firstDay) {
                if (startDate <= endDate) {
                    arrDate.push(`${startDate}`)
                    startDate++
                } else {
                    arrDate.push('')
                }
            } else {
                arrDate.push('')
            }
        }
        this.setState({ arrDate, month, year })
    }
    next = () => {
        let nextMonth = moment(`${this.state.year}-${this.state.month}-01`).add(1, 'M').format('YYYY-MM-DD')
        this.setState({
            year: moment(nextMonth).format('YYYY'),
            month: moment(nextMonth).format("MM")
        })
        this.generateDate(nextMonth)
    }
    prev = () => {
        let prevMonth = moment(`${this.state.year}-${this.state.month}-01`).subtract(1, 'M').format('YYYY-MM-DD')
        this.setState({
            year: moment(prevMonth).format('YYYY'),
            month: moment(prevMonth).format("MM")
        })
        this.generateDate(prevMonth)
    }
    onDateSelected = (value) => {
        if(Number(value) < 10) { value = `0${value}`}
        const { onSelect } = this.props
        onSelect(`${this.state.year}-${this.state.month}-${value}`)
        this.onCloseDialog()
    }

    // ====================================================================================
    // ========== MONTH LISTENER SECTIONS =================================================
    // ====================================================================================
    onMonthPrev = () => {
        let year = this.state.year
        year = Number(year) - 1
        year = year.toString()
        this.setState({year})
    }
    onMonthNext = () => {
        let year = this.state.year
        year = moment(year, "YYYY").add(1, 'years')
        year = moment(year).format("YYYY")
        this.setState({year})
    }
    onMonthSelected = (value) => {
        let selectedDate = `${this.state.year}-${value}-01`
        this.setState({
            selectedDate:"", 
            showMode:"date",
            arrYears: [],
        })
        setTimeout(() => {
            this.generateDate(selectedDate)
        }, 10)
    }

    // ====================================================================================
    // ========== YEAR LISTENER SECTIONS ==================================================
    // ====================================================================================
    onYearInit = () => {
        let splitDate = this.state.selectedDate !== "" && this.state.selectedDate !== "-" ? this.state.selectedDate.split("-") : moment().format("YYYY-MM-DD")
        let selectedYear = splitDate[0]
        let arrYears = []
        arrYears.push(selectedYear)
        for (let index = 1; index < 18; index++) {
            let year = Number(selectedYear)
            year = year - index
            arrYears.push(year.toString())
        }
        arrYears.sort()

        this.setState({ showMode:"year", year:selectedYear, arrYears })
    }
    onYearPrev = (first) => {
        let arrYears = []
        arrYears.push(first)
        for (let index = 1; index < 12; index++) {
            let year = Number(first)
            year = year - index
            arrYears.push(year.toString())
        }
        arrYears.sort()

        this.setState({ year:"", arrYears })
    }
    onYearNext = (last) => {
        let arrYears = []
        last = moment(last, "YYYY").add(11, 'years')
        last = moment(last).format("YYYY")
        arrYears.push(last)
        for (let index = 1; index < 12; index++) {
            let year = Number(last)
            year = year - index
            arrYears.push(year.toString())
        }
        arrYears.sort()

        this.setState({ year:"", arrYears })
    }
    onYearSelected = (value) => {
        this.setState({
            year:value, 
            showMode:"month",
            arrYears: [],
        })
    }

    // ====================================================================================
    // ========== RENDER SECTIONS =========================================================
    // ====================================================================================
    render() {
        return (
            <Dialog onClose={this.onCloseDialog} open={this.state.isShowModal}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width:300,
                    height:380,
                    }}>
                    <div style={{ maxWidth:350, display:"flex", flexDirection:"column" }}>
                        {this.renderHeader()}
                        <div style={{display:"block", height:280, maxWidth:270, minWidth:270}}>
                            {this.renderContents()}
                        </div>
                        {this.renderFooter()}
                    </div>
                </Box>
            </Dialog>
        )
    }
    renderHeader() {
        if(this.state.showMode === 'date') {
            return this.renderDateOption()
        }
        if(this.state.showMode === 'month') {
            return this.renderMonthOption()
        }
        if(this.state.showMode === 'year') {
            return this.renderYearOption()
        }
    }
    renderContents() {
        if(this.state.showMode === 'date') {
            return this.renderDatePicker()
        }
        if(this.state.showMode === 'month') {
            return this.renderMonthPicker()
        }
        if(this.state.showMode === 'year') {
            return this.renderYearPicker()
        }
    }
    renderFooter() {
        let description = 'Pilih tanggal untuk melanjutkan.'
        if(this.state.showMode === 'month') {
            description = 'Pilih bulan untuk melanjutkan.'
        }
        if(this.state.showMode === 'year') {
            description = 'Pilih tahun untuk melanjutkan.'
        }

        return(
            <div style={{marginBottom:20, padding:"0 20px 0 20px", textAlign:'center'}} >
                <div style={{fontWeight:fontFamilyRegular, fontSize:12, color:NOTE_COLOR}}>
                    {description}
                </div>
            </div>
        )
    }

    // ====================================================================================
    // ========== DATE PICKER RENDER OPTIONS ==============================================
    // ====================================================================================
    renderDateOption() {
        let isPrev = true
        let isNext = true
        let displayMonthYear = `${this.state.year}-${this.state.month}`

        return(
            <div style={{display:"flex", flexDirection:'row', alignItems:'center', justifyContent:'space-between', 
                paddingLeft:20, paddingRight:20,
                height:50, marginTop:10
                }}>
                <IconButton onClick={() => { this.prev() }} edge="start">
                    <Icon style={{ color: isPrev ? 'var(--primary-color)' : NAV_INACTIVE_COLOR }} >west</Icon>
                </IconButton>
                <span style={{
                    border:`solid 1px var(--primary-color)`, 
                    padding:"5px 15px 5px 15px",
                    borderRadius:5,
                    cursor:"pointer"
                    }}
                    onClick={() => { this.setState({showMode:'month', month:"-"}) }}
                    >
                    <span style={{textAlign:'center', color:'var(--primary-color)', fontWeight:fontFamilyBold, fontSize:15}}>
                        {moment(displayMonthYear).format("MMMM")} {moment(displayMonthYear).format("YYYY")}
                    </span>
                </span>
                <IconButton onClick={() => { if(isNext) { this.next() } }} edge="end">
                    <Icon style={{ color: isNext ? 'var(--primary-color)' : NAV_INACTIVE_COLOR }} >east</Icon>
                </IconButton>
            </div>
        )
    }
    renderDatePicker() {
        let selectedDate = this.state.selectedDate
        let activeDate = "-"
        let todayDate = "-"
        if(`${this.state.year}-${this.state.month}` === moment(selectedDate).format("YYYY-MM")) {
            activeDate = moment(selectedDate).format("D")
        }
        if(`${this.state.year}-${this.state.month}` === moment().format("YYYY-MM")) {
            todayDate = moment().format("D")
        }

        return(
            <div style={{ textAlign:"center" }}>
                <div style={{ display:"grid", gridTemplateColumns:"repeat(7, 1fr)" }}>
                    {this.state.arrDate.map((item, index) => {
                        let fontSize = 14
                        let isButton = true
                        if(item === 'Sen' || 
                            item === 'Sel' || 
                            item === 'Rab' || 
                            item === 'Kam' || 
                            item === 'Jum' || 
                            item === 'Sab' || 
                            item === 'Min'
                        ) {  
                            fontSize = 12
                            isButton = false
                        }
                        return(
                            <div key={index} style={{textAlign:"center"}}>
                                <div 
                                    style={{
                                        marginTop:15,
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        backgroundColor:activeDate === item ? 'var(--primary-color)': ITEM_BG_COLOR,
                                        borderRadius:30/2,
                                        padding:"5px 0 5px 0",
                                        cursor:"pointer"
                                    }}
                                    onClick={() => {
                                        if(isButton) { this.onDateSelected(item) }
                                    }}
                                    >
                                    <span
                                        style={{
                                            color:activeDate === item ? ITEM_SELECTED_COLOR : ITEM_COLOR,
                                            fontWeight:activeDate === item ? fontFamilyBold : fontFamilyRegular,
                                            fontSize:fontSize
                                        }}
                                        >
                                        {item}
                                    </span>
                                </div>
                                {todayDate === item &&
                                    <span style={{
                                        width:4, height:4, borderRadius:4, 
                                        backgroundColor:activeDate !== item ? 'var(--primary-color)' : CURRENT_COLOR_ACTIVE, 
                                        display:"inline-grid",
                                        justifyContent:"center",
                                        position:"absolute"
                                    }}/>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    // ====================================================================================
    // ========== MONTH PICKER RENDER OPTIONS =============================================
    // ====================================================================================
    renderMonthOption() {
        let selectedYear = this.state.year
        let isPrev = true
        let isNext = true

        return(
            <div style={{display:"flex", flexDirection:'row', alignItems:'center', justifyContent:'space-between', 
                paddingLeft:20, paddingRight:20,
                height:50, marginTop:10
                }}>
                <IconButton onClick={() => { this.onMonthPrev() }} edge="start">
                    <Icon style={{ color: isPrev ? 'var(--primary-color)' : NAV_INACTIVE_COLOR }} >west</Icon>
                </IconButton>
                <span style={{
                    border:`solid 1px var(--primary-color)`, 
                    padding:"5px 15px 5px 15px",
                    borderRadius:5,
                    cursor:"pointer"
                    }}
                    onClick={() => { this.onYearInit() }}
                    >
                    <span style={{textAlign:'center', color:'var(--primary-color)', fontWeight:fontFamilyBold, fontSize:15}}>
                        {selectedYear}
                    </span>
                </span>
                <IconButton onClick={() => { if(isNext) { this.onMonthNext() } }} edge="end">
                    <Icon style={{ color: isNext ? 'var(--primary-color)' : NAV_INACTIVE_COLOR }} >east</Icon>
                </IconButton>
            </div>
        )
    }
    renderMonthPicker() {
        let selectedMonth = this.state.month
        let thisMonth = "-"
        if(this.state.year === moment().format("YYYY")) {
            thisMonth = moment().format("MM")
        }

        return(
            <div style={{ textAlign:"center", marginBottom:20 }}>
                <div style={{ display:"grid", gridTemplateColumns:"repeat(3, 1fr)" }}>
                    {ARR_MONTH.map((item, index) => {
                        return(
                            <div key={index} style={{textAlign:"center"}}>
                                <div
                                    style={{
                                        marginTop:15,
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        backgroundColor:item.value === selectedMonth ? 'var(--primary-color)' : ITEM_BG_COLOR,
                                        borderRadius:30/2,
                                        padding:"5px 0 5px 0",
                                        cursor:"pointer"
                                    }}
                                    onClick={() => { this.onMonthSelected(item.value) }}
                                    >
                                    <div
                                        style={{
                                            color:item.value === selectedMonth ? ITEM_SELECTED_COLOR : ITEM_COLOR,
                                            fontWeight:item.value === selectedMonth ? fontFamilyBold : fontFamilyRegular,
                                            fontSize:12
                                        }}
                                        >
                                        {item.title}
                                    </div>
                                </div>
                                {thisMonth === item.value &&
                                    <div style={{
                                        width:4, height:4, borderRadius:4, 
                                        backgroundColor:item.value !== selectedMonth ? 'var(--primary-color)' : CURRENT_COLOR_ACTIVE, 
                                        display:"inline-grid",
                                        justifyContent:"center",
                                        position:"absolute"
                                    }}/>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    // ====================================================================================
    // ========== YEAR PICKER RENDER OPTIONS ==============================================
    // ====================================================================================
    renderYearOption() {
        let isPrev = true
        let isNext = true
        let arrYears = this.state.arrYears
        let firstYear = ""
        let lastYear = ""
        if(arrYears.length > 0) {
            let lsIndex = arrYears.length - 1
            firstYear = arrYears[0]
            lastYear = arrYears[lsIndex]
        }

        return(
            <div style={{
                display:"flex", flexDirection:'row', alignItems:'center', justifyContent:'space-between', 
                paddingLeft:20, paddingRight:20,
                height:50, marginTop:10
                }}>
                <IconButton onClick={() => { this.onYearPrev(firstYear) }} edge="start">
                    <Icon style={{ color: isPrev ? 'var(--primary-color)' : NAV_INACTIVE_COLOR }} >west</Icon>
                </IconButton>
                <span style={{ border:`solid 1px var(--primary-color)`, padding:"5px 15px 5px 15px", borderRadius:5 }}>
                    <span style={{textAlign:'center', color:'var(--primary-color)', fontWeight:fontFamilyBold, fontSize:15}}>
                        Pilih Tahun
                    </span>
                </span>
                <IconButton onClick={() => { if(isNext) { this.onYearNext(lastYear) } }} edge="end">
                    <Icon style={{ color: isNext ? 'var(--primary-color)' : NAV_INACTIVE_COLOR }} >east</Icon>
                </IconButton>
            </div>
        )
    }
    renderYearPicker() {
        let thisYear = moment().format("YYYY")
        let arrData = this.state.arrYears
        
        if(arrData.length > 0) {
            return(
                <div style={{ textAlign:"center", marginBottom:20 }}>
                    <div style={{ display:"grid", gridTemplateColumns:"repeat(3, 1fr)" }}>
                        {arrData.map((item, index) => {
                            return(
                                <div key={index} style={{textAlign:"center"}}>
                                    <div
                                        style={{
                                            marginTop:15,
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                            padding:"5px 0 5px 0",
                                            cursor:"pointer"
                                        }}
                                        onClick={() => { this.onYearSelected(item) }}
                                        >
                                        <div
                                            style={{
                                                color:DEFAULT_FT_COLOR,
                                                fontWeight:fontFamilyRegular,
                                                fontSize:14
                                            }}
                                            >
                                            {item}
                                        </div>
                                    </div>
                                    {thisYear === item &&
                                        <div style={{
                                            width:4, height:4, borderRadius:4, 
                                            backgroundColor:'var(--primary-color)', 
                                            display:"inline-grid",
                                            justifyContent:"center",
                                            position:"absolute"
                                        }}/>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }
}