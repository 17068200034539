export default function FormInputFooter({ children, type, errorText }) {
  return (
    <div style={{
      marginTop: 5, display: "flex", flexDirection: "row", justifyContent: "space-between",
      paddingLeft: 5, paddingRight: 5
    }}>
      <span style={{
        flex: 1,
        paddingRight: 20,
        color: type === 'required' ? "#e74c3c" : '#1abc9c',
        fontSize: 11
      }}>{type === 'required' ? '*Wajib' : 'Opsional'}</span>
      {errorText !== undefined && errorText !== null && errorText !== '' &&
        <span style={{
          textAlign: "right",
          color: "red",
          fontSize: 11
        }}>{errorText}</span>
      }
    </div>
  )
}