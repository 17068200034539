/*
Created by esoda
Created on Nov, 2023
Contact esoda.id
Last modified: -
    - Read changelog.md
*/

import React from 'react'
import 'styles/Splash.css'
import Button from '@mui/material/Button'
import { Icon } from '@mui/material'

export default class Custom4040 extends React.Component {
    // ====================================================================================
    // ========== RENDER SECTIONS =========================================================
    // ====================================================================================
    render() {
        return (
            <>
                <main className={`main_splash`}>
                    <div className={'main_splash_container'}
                        style={{ paddingLeft: 50, paddingRight: 50 }}
                    >
                        <img
                            className=""
                            src="/assets/images/image-login.png"
                            alt="esoftdream.net"
                            width={250}
                            height={114}
                            style={{ objectFit:"contain" }}
                        />

                        <h2 style={{
                            marginTop: 50,
                            color: "#fff"
                        }}>404 | <small>Halaman Tidak Ditemukan</small></h2>

                        <Button
                            style={{
                                fontWeight: "bold", fontSize: 14, marginTop: 30,
                                backgroundColor: "#fff", color: "#D31146"
                            }}
                            sx={{ height: 40 }}
                            variant="contained"
                            onClick={() => {
                                window.history.back()
                            }}
                        >
                            <Icon>arrow_back</Icon> Kembali Ke Halaman Sebelumnya
                        </Button>

                        <div className={'footers'}>
                            <span className={'font_family'} style={{ marginRight: 5 }}>Powered by</span>
                            <a href="https://esoftdream.net" target='_blank' rel="noreferrer">
                                <span>Esoda</span>
                            </a>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}