import React from "react";

const InputRequired = ({ required, errors, label, size }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "5px 0px",
      }}
    >
      <span
        style={{
          flex: 1,
          paddingRight: 20,
          color: required ? "#e74c3c" : "#27ae60",
          fontSize: size !== undefined ? size : 11,
          textAlign: "left",
          textTransform: "capitalize",
          letterSpacing: "0.3px",
        }}
      >
        {required ? "Wajib" : "Opsional"}
      </span>
      {errors !== undefined && errors !== null ? (
        <span
          style={{
            textAlign: "right",
            color: "red",
            textTransform: "capitalize",
            fontSize: size !== undefined ? size : 11,
            letterSpacing: "0.3px",
          }}
        >
          {errors}
        </span>
      ) : (
        <>
          {label !== undefined && label !== "" && (
            <span
              style={{
                textAlign: "right",
                color: "#999",
                fontSize: size !== undefined ? size : 11,
                textTransform: "capitalize",
                letterSpacing: "0.3px",
              }}
            >
              {label}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default InputRequired;
