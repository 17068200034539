import React from "react"
import Loading from "./Loading"
import { Alert, Box, FormControl, Icon, IconButton, InputAdornment, Modal, OutlinedInput, Snackbar } from "@mui/material"
import ApiHelpers from "utils/ApiHelpers"
import InputRequired from "./InputRequired"

export default class ModalAccount extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      showDialog: false,
      inputs: {
        "name": "",
        "email": "",
        "mobilephone": "",
        "address": "",
        "old_password": "",
        "new_password": ""
      },
      errors: {},
      showPassword: false,

      showSnackbar: false,
      messageSnackbar: "",
      severitySnackbar: "info",
    }
    this.firstMount = true
  }

  componentDidMount(){
    if(this.firstMount){
      this.firstMount = false
    }
  }

  componentWillUnmount() { }

  // ====================================================================================
  // ========== INITIALIZE, GET DATA ====================================================
  // ====================================================================================

  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================
  onShowDialog = (userData=null) => { 
    let inputs = this.state.inputs
    inputs.name = userData.name || ""
    inputs.mobilephone = userData.mobilephone || ""
    inputs.email = userData.email || ""
    inputs.address = userData.address || ""

    this.setState({ showDialog:true, inputs }) 
    // setTimeout(() => { document.getElementById("input-account-name").focus() }, 250)
  }
  onCloseDialog = () => { this.setState({ showDialog:false, 
    inputs: {
      "name": "",
      "email": "",
      "mobilephone": "",
      "address": "",
      "old_password": "",
      "new_password": ""
    },
    errors: {},  
  })}
  onShowPassword = () => { this.setState({ showPassword: !this.state.showPassword }) }
  onTextInputListeners = (text, input) => {
    let inputs = this.state.inputs
    inputs[input] = text
    this.setState(prevState => ({ ...prevState, inputs }))
  }
  onTextErrorListeners = (error, input) => {
    let errors = this.state.errors
    errors[input] = error
    this.setState(prevState => ({ ...prevState, errors }))
  }
  onNextInputListeners = (e, current, required, next) => {
    this.onTextErrorListeners(null, current)
    if (e.key === 'Enter') {
      if (next === "SUBMIT") {
        this.onValidateInputListeners()
      } else {
        if (required) {
          if (!this.state.inputs[current]) {
            this.onTextErrorListeners('Harus diisi', current);
          } else {
            if(next !== "") {  document.getElementById(next).focus() }
          }
        } else {
          if(next !== "") {  document.getElementById(next).focus() }
        }
      }
    }
  }
  onSaveProfileListeners = async () => {
    let inputs = this.state.inputs;
    let isValid = true;

    if (!inputs.name) {
      this.onTextErrorListeners("Harus diisi", "name");
      isValid = false;
    }
    if (!inputs.mobilephone) {
      this.onTextErrorListeners("Harus diisi", "mobilephone");
      isValid = false;
    }
        
    if (isValid) {
      this.ref_LoadingDialog.onShowDialog()
      let response = await ApiHelpers.post('profile/update', {
        "name": this.state.inputs.name,
        "mobilephone": this.state.inputs.mobilephone,
        "email": this.state.inputs.email,
        "address": this.state.inputs.address,
      })
      if(response.status === 200) {
        this.setState({
          showSnackbar: true,
          messageSnackbar: response.message,
          severitySnackbar: "success",
        })
        const { onSubmit } = this.props
        if(onSubmit) {
          onSubmit({
            "name": this.state.inputs.name,
            "mobilephone": this.state.inputs.mobilephone,
            "email": this.state.inputs.email,
            "address": this.state.inputs.address
          })
        }
      } else {
        this.onTextErrorListeners(null, "name")
        this.onTextErrorListeners(null, "mobilephone")
        this.onTextErrorListeners(null, "email")
        this.onTextErrorListeners(null, "address")
        if(response.status === 412 && response.results.data !== undefined) {
          if(response.results.data.name !== undefined && response.results.data.name !== "") {
            this.onTextErrorListeners(response.results.data.name, "name")
          }
          if(response.results.data.mobilephone !== undefined && response.results.data.mobilephone !== "") {
            this.onTextErrorListeners(response.results.data.mobilephone, "mobilephone")
          }
          if(response.results.data.email !== undefined && response.results.data.email !== "") {
            this.onTextErrorListeners(response.results.data.email, "email")
          }
          if(response.results.data.address !== undefined && response.results.data.address !== "") {
            this.onTextErrorListeners(response.results.data.address, "address")
          }
        }
        this.setState({
          showSnackbar: true,
          messageSnackbar: response.message,
          severitySnackbar: "error",
        })
      }
      this.ref_LoadingDialog.onCloseDialog()
    }
  }
  onSavePasswordListeners = async () => {
    let inputs = this.state.inputs;
    let isValid = true;

    if (!inputs.old_password) {
      this.onTextErrorListeners("Harus diisi", "old_password");
      isValid = false;
    }
    if (!inputs.new_password) {
      this.onTextErrorListeners("Harus diisi", "new_password");
      isValid = false;
    }
        
    if (isValid) {
      this.ref_LoadingDialog.onShowDialog()
      let response = await ApiHelpers.post('profile/change_password', {
        "old_password": this.state.inputs.old_password,
        "new_password": this.state.inputs.new_password
      })
      if(response.status === 200) {
        this.setState({
          showSnackbar: true,
          messageSnackbar: response.message,
          severitySnackbar: "success",
        })
        let inputs = this.state.inputs
        inputs.old_password = ""
        inputs.new_password = ""
        this.setState({ inputs })
      } else {
        this.onTextErrorListeners(null, "old_password")
        this.onTextErrorListeners(null, "new_password")
        if(response.status === 412 && response.results.data !== undefined) {
          if(response.results.data.old_password !== undefined && response.results.data.old_password !== "") {
            this.onTextErrorListeners(response.results.data.old_password, "old_password")
          }
          if(response.results.data.new_password !== undefined && response.results.data.new_password !== "") {
            this.onTextErrorListeners(response.results.data.new_password, "new_password")
          }
        }
        this.setState({
          showSnackbar: true,
          messageSnackbar: response.message,
          severitySnackbar: "error",
        })
      }
      this.ref_LoadingDialog.onCloseDialog()
    }
  }

  // ====================================================================================
  // ========== RENDER SECTION ==========================================================
  // ====================================================================================
  render(){
    return(
      <>
        <Modal open={this.state.showDialog} onClose={this.onCloseDialog}>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100wh",
              height: "100vh",
            }}
          >
            <div className={`my_modal_detail_container my_modal_detail_container_small`}>
              <div className={"toolbar"}>
                <div className={"title"}>Data Profil</div>
                <div className={"close"} onClick={this.onCloseDialog}>
                  <Icon color="primary">close</Icon>
                </div>
              </div>
              <div className={"content"}>
                <div className={"flex"}>
                  <div className={"left"}>{this.renderLeftContent()}</div>
                  <div className={"right"}>{this.renderRightContent()}</div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>

        {/* LOADING DIALOG */}
        <Loading ref={(value) => (this.ref_LoadingDialog = value)} />

        {/* SNACKBAR & ALERT DIALOG */}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          style={{ bottom: 80 }}
          open={this.state.showSnackbar}
          autoHideDuration={4600}
          onClose={() => {
              this.setState({ showSnackbar: false, messageSnackbar: "" });
          }}
        >
          <Alert
            severity={this.state.severitySnackbar}
            onClose={() => {
              this.setState({ showSnackbar: false, messageSnackbar: "" });
            }}
            sx={{ width: "100%" }}
          >
            {this.state.messageSnackbar}
          </Alert>
        </Snackbar>
      </>
    )
  }
  renderLeftContent(){
    return (
      <>
        <h5 style={{ marginBottom: 10 }}>Profil Anda</h5>

        <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
          <span style={{ textAlign: "left", fontSize: 13, color: "#666", marginBottom: 5}}>Nama Anda</span>
          <OutlinedInput
            id="input-name"
            startAdornment={<InputAdornment position="start"><Icon>account_circle</Icon></InputAdornment>}
            placeholder="Tuliskan nama Anda..."
            value={this.state.inputs.name}
            onChange={(event) => {
              if ( event.target !== undefined && event.target.value !== undefined ) {
                this.onTextInputListeners(event.target.value, "name");
              }
            }}
            error={ this.state.errors.name !== undefined && this.state.errors.name !== null ? true : false }
            onFocus={() => this.onTextErrorListeners(null, "name")}
            sx={{ "& input::placeholder": { color: "#999" }, backgroundColor: "#f7f7f7"}}
          />
          <InputRequired
            required
            errors={this.state.errors.name}
            size={11}
            label={"Harus diisi"}
          />
        </FormControl>

        <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
          <span style={{ textAlign: "left", fontSize: 13, color: "#666", marginBottom: 5}}>Username</span>
          <OutlinedInput
            id="input-username"
            startAdornment={<InputAdornment position="start"><Icon>key</Icon></InputAdornment>}
            placeholder="Tuliskan username Anda..."
            value={this.state.inputs.email}
            onChange={(event) => {
              if ( event.target !== undefined && event.target.value !== undefined ) {
                this.onTextInputListeners(event.target.value, "email");
              }
            }}
            error={ this.state.errors.email !== undefined && this.state.errors.email !== null ? true : false }
            onFocus={() => this.onTextErrorListeners(null, "email")}
            sx={{ "& input::placeholder": { color: "#999" }, backgroundColor: "#f7f7f7"}}
            disabled
          />
          <InputRequired
            required
            errors={this.state.errors.email}
            size={11}
            label={""}
          />
        </FormControl>

        <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
          <span style={{ textAlign: "left", fontSize: 13, color: "#666", marginBottom: 5}}>Mobilephone</span>
          <OutlinedInput
            id="input-mobilephone"
            startAdornment={<InputAdornment position="start"><Icon>call</Icon></InputAdornment>}
            placeholder="Tuliskan mobilephone Anda..."
            value={this.state.inputs.mobilephone}
            onChange={(event) => {
              if ( event.target !== undefined && event.target.value !== undefined ) {
                this.onTextInputListeners(event.target.value, "mobilephone");
              }
            }}
            error={ this.state.errors.mobilephone !== undefined && this.state.errors.mobilephone !== null ? true : false }
            onFocus={() => this.onTextErrorListeners(null, "mobilephone")}
            sx={{ "& input::placeholder": { color: "#999" }, backgroundColor: "#f7f7f7"}}
          />
          <InputRequired
            required={false}
            errors={this.state.errors.mobilephone}
            size={11}
            label={""}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
          <span style={{ textAlign: "left", fontSize: 13, color: "#666", marginBottom: 5}}>Alamat</span>
          <OutlinedInput
            id="input-address"
            startAdornment={<InputAdornment position="start"><Icon>maps</Icon></InputAdornment>}
            placeholder="Tuliskan alamat Anda..."
            value={this.state.inputs.address}
            onChange={(event) => {
              if ( event.target !== undefined && event.target.value !== undefined ) {
                this.onTextInputListeners(event.target.value, "address");
              }
            }}
            error={ this.state.errors.address !== undefined && this.state.errors.address !== null ? true : false }
            onFocus={() => this.onTextErrorListeners(null, "address")}
            sx={{ "& input::placeholder": { color: "#999" }, backgroundColor: "#f7f7f7"}}
          />
          <InputRequired
            required={false}
            errors={this.state.errors.address}
            size={11}
            label={""}
          />
        </FormControl>

        <div className={'form_act_btn'}
          style={{
              marginTop: 20,
              justifyContent:"center",
              height: 40, 
              color: "#fff",
              backgroundColor: 'var(--primary-color)',
              padding: "0 10px",
              fontSize: 14
          }}
          onClick={() => { this.onSaveProfileListeners() }}
        >
          <Icon style={{ fontSize: 16, marginRight: 7.5 }}>person_check</Icon>
          <div>Ubah Profil</div>
        </div>
      </>
    )
  }
  renderRightContent(){
    return (
      <>
        <h5 style={{ marginBottom: 10 }}>Password</h5>

        <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
          <span style={{ textAlign: "left", fontSize: 13, color: "#666", marginBottom: 5}}>Password lama</span>
          <OutlinedInput
            id="input-old_password"
            type={this.state.showPassword ? 'text' : 'password'}
            startAdornment={<InputAdornment position="start"><Icon>account_circle</Icon></InputAdornment>}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.onShowPassword}
                  edge="end"
                >
                    <Icon>{this.state.showPassword ? "visibility_off": "visibility"}</Icon>
                </IconButton>
              </InputAdornment>
            }
            placeholder="Tuliskan password lama Anda..."
            value={this.state.inputs.old_password}
            onChange={(event) => {
              if ( event.target !== undefined && event.target.value !== undefined ) {
                this.onTextInputListeners(event.target.value, "old_password");
              }
            }}
            error={ this.state.errors.old_password !== undefined && this.state.errors.old_password !== null ? true : false }
            onFocus={() => this.onTextErrorListeners(null, "old_password")}
            sx={{ "& input::placeholder": { color: "#999" }, backgroundColor: "#f7f7f7"}}
          />
          <InputRequired
            required
            errors={this.state.errors.old_password}
            size={11}
            label={"Harus diisi"}
          />
        </FormControl>

        <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
          <span style={{ textAlign: "left", fontSize: 13, color: "#666", marginBottom: 5}}>Password baru</span>
          <OutlinedInput
            id="input-new_password"
            type={this.state.showPassword ? 'text' : 'password'}
            startAdornment={<InputAdornment position="start"><Icon>account_circle</Icon></InputAdornment>}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.onShowPassword}
                  edge="end"
                >
                    <Icon>{this.state.showPassword ? "visibility_off": "visibility"}</Icon>
                </IconButton>
              </InputAdornment>
            }
            placeholder="Tuliskan password baru Anda..."
            value={this.state.inputs.new_password}
            onChange={(event) => {
              if ( event.target !== undefined && event.target.value !== undefined ) {
                this.onTextInputListeners(event.target.value, "new_password");
              }
            }}
            error={ this.state.errors.new_password !== undefined && this.state.errors.new_password !== null ? true : false }
            onFocus={() => this.onTextErrorListeners(null, "new_password")}
            sx={{ "& input::placeholder": { color: "#999" }, backgroundColor: "#f7f7f7"}}
          />
          <InputRequired
            required
            errors={this.state.errors.new_password}
            size={11}
            label={"Harus diisi"}
          />
        </FormControl>

        <div className={'form_act_btn'}
          style={{
              marginTop: 20,
              justifyContent:"center",
              height: 40, 
              color: "#fff",
              backgroundColor: 'var(--primary-color)',
              padding: "0 10px",
              fontSize: 14
          }}
          onClick={() => { this.onSavePasswordListeners() }}
        >
          <Icon style={{ fontSize: 16, marginRight: 7.5 }}>key</Icon>
          <div>Ubah Password</div>
        </div>
      </>
    )
  }
}