import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#9334F6',
      main: '#9334F6',
      dark: '#9334F6',
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 1,
  typography: {
    button: {
      textTransform: 'none',
    },
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <ThemeProvider theme={theme}>
        <App/>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>
);

console.warn = console.error = () => {}