/*
Created by esoda
Created on Nov, 2023
Contact esoda.id
*/

import {
  Modal as MuiModal,
  Box,
  IconButton,
  Icon,
} from "@mui/material";

function Modal({ children, show, onModalClose, btnCloseTitle }) {
  let titleSection, bodySection, footerSection;

  children.forEach((child) => {
    if (child && child.type && child.type.name === ModalTitle.name) {
      titleSection = (
        <ModalTitle sx={child.props.sx} onClose={onModalClose}>
          {child.props.children}
        </ModalTitle>
      );
    }
    if (child && child.type && child.type.name === ModalBody.name) {
      bodySection = (
        <ModalBody sx={child.props.sx}>{child.props.children}</ModalBody>
      );
    }
    if (child && child.type && child.type.name === ModalFooter.name) {
      footerSection = (
        <ModalFooter
          sx={child.props.sx}
          onClose={onModalClose}
          closeTitle={btnCloseTitle}
        >
          {child.props.children}
        </ModalFooter>
      );
    }
  });

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={show}
      onClose={onModalClose}
    >
      <Box
        sx={{
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          width: "100wh",
          height: "100vh",
        }}
      >
        <div className="my_modal_container">
          {titleSection}
          {bodySection}
          {footerSection}
        </div>
      </Box>
    </MuiModal>
  );
}

function ModalTitle({ children, onClose, sx }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 8,
        py: 8,
        borderBottom: 1,
        borderBottomColor: "#e1e1e1",
        ...sx,
      }}
    >
      {children}
      <IconButton onClick={onClose} color="primary" edge="end">
        <Icon style={{ color: "#e74c3c" }}>close</Icon>
      </IconButton>
    </Box>
  );
}

function ModalBody({ children, sx }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: 8,
        py: 16,
        maxHeight: "calc(100vh - 200px)",
        overflow: "auto",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

function ModalFooter({ children, onClose, sx, closeTitle }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        px: 0,
        py: 8,
        borderTop: 1,
        borderTopColor: "#e1e1e1",
        ...sx,
      }}
    >
      {children}
      <div
        className={'form_act_btn'}
        style={{
          height: 40,
          color: "#e74c3c",
          border: `solid 1px #e74c3c`,
          padding: "0 10px",
          marginLeft: 10,
          fontSize: 14,
        }}
        onClick={onClose}
      >
        <Icon style={{ fontSize: 16, marginRight: 7.5 }}>highlight_off</Icon>
        <div>{closeTitle !== undefined ? closeTitle : "TUTUP"}</div>
      </div>
    </Box>
  );
}

export { Modal, ModalBody, ModalTitle, ModalFooter };
