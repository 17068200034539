import { Alert, FormControl, Icon, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, Snackbar, Tooltip } from "@mui/material";
import InputRequired from "components/InputRequired";
import Loading from "components/Loading";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "components/Modal";
import TextFieldNumberFormat from "components/TextFieldNumberFormat";
import React from "react";
import ApiHelpers from "utils/ApiHelpers";
import Constants from "utils/Constant";

export default class ModalMerchantForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,

      modalType: "delete",
      modalData: null,
      modalIndex: -1,

      showSnackbar: false,
      severitySnackbar: "",
      messageSnackbar: "",

      showConfirmation: false,
      inputs: {
        id: "",
        name: "",
        airport: "",
        type: "",
        terminal: "",
        address: "",
        reduce_percent: 0,
        accurate_client_id: "",
        accurate_client_secret: "",
        is_active_bool: true,
        api_key: '',
        api_url: '',
      },
      errors: {},
    };
  }

  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================
  onShowDialog = (modalType, modalData, modalIndex) => {
    let inputs = this.state.inputs;
    if (modalType === "edit" && modalData !== null) {
      inputs.id = modalData.id
      inputs.name = modalData.name
      inputs.airport = modalData.airport
      inputs.type = modalData.type
      inputs.terminal = modalData.terminal
      inputs.address = modalData.address
      inputs.reduce_percent = modalData.reduce_percent
      inputs.accurate_client_id = modalData.accurate_client_id
      inputs.accurate_client_secret = modalData.accurate_client_secret
      inputs.is_active_bool = modalData.is_active_bool
      inputs.api_key = modalData.api_key
      inputs.api_url = modalData.api_url
    }
    this.setState({
      showModal: true,
      modalType,
      modalData,
      modalIndex,
      inputs,
    });
    if (modalType === "add" || modalType === "edit") {
      setTimeout(() => {
        document.getElementById("input-name").focus();
      }, 250);
    }
  };
  onCloseDialog = () => {
    this.setState({
      showModal: false,
      modalType: "delete",
      modalData: null,
      modalIndex: -1,
      inputs: {
        id: "",
        name: "",
        airport: "",
        type: "",
        terminal: "",
        address: "",
        reduce_percent: 0,
        accurate_client_id: "",
        accurate_client_secret: "",
        api_key: "",
        api_url: "",
        is_active_bool: true,
      },
      errors: {},
    });
  };
  onTextInputListeners = (text, input) => {
    let inputs = this.state.inputs;
    inputs[input] = text;
    this.setState((prevState) => ({ ...prevState, inputs }));
  };
  onTextErrorListeners = (error, input) => {
    let errors = this.state.errors;
    errors[input] = error;
    this.setState((prevState) => ({ ...prevState, errors }));
  };
  onActivatedListeners = async () => {
    this.ref_LoadingDialog.onShowDialog();
    let response = await ApiHelpers.post("client/change_status", {
      id: this.state.modalData.id,
      is_active_bool: !this.state.modalData.is_active_bool,
    });
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(response, this.state.modalType, this.state.modalIndex);
    }
    this.ref_LoadingDialog.onCloseDialog();
  };
  onDeleteListeners = async () => {
    this.ref_LoadingDialog.onShowDialog();
    let response = await ApiHelpers.post(
      "client/delete",
      {
        id: this.state.modalData.id,
      }
    );
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(response);
    }
    this.ref_LoadingDialog.onCloseDialog();
  };
  onValidateAddEditListeners = () => {
    let inputs = this.state.inputs;
    let isValid = true;

    if (!inputs.name) {
      this.onTextErrorListeners("Harus diisi", "name");
      isValid = false;
    } else if (inputs.name.length < 3) {
      this.onTextErrorListeners("Minimal 3 karakter", "name");
      isValid = false;
    }

    if (!inputs.terminal) {
      this.onTextErrorListeners("Harus diisi", "terminal");
      isValid = false;
    }

    if (!inputs.type) {
      this.onTextErrorListeners("Harus diisi", "type");
      isValid = false;
    }

    if (!inputs.reduce_percent) {
      this.onTextErrorListeners("Harus diisi", "reduce_percent");
      isValid = false;
    }

    if (!inputs.accurate_client_id) {
      this.onTextErrorListeners("Harus diisi", "accurate_client_id");
      isValid = false;
    }

    if (!inputs.api_key) {
      this.onTextErrorListeners("Harus diisi", "api_key");
      isValid = false;
    }

    if (!inputs.api_url) {
      this.onTextErrorListeners("Harus diisi", "api_url");
      isValid = false;
    }

    if (!inputs.accurate_client_secret) {
      this.onTextErrorListeners("Harus diisi", "accurate_client_secret");
      isValid = false;
    }

    if (inputs.address !== '') {
      if(inputs.address.length < 10){
        this.onTextErrorListeners("Minimal 10 karakter", "address");
        isValid = false;
      }
    }

    if (isValid) {
      this.setState({ showConfirmation: true });
    }
  };
  onSubmitAddEditListeners = async () => {
    this.ref_LoadingDialog.onShowDialog();
    let apiEndPoint = "client/create";
    if (this.state.modalType === "edit") {
      apiEndPoint = "client/update";
    }
    let response = await ApiHelpers.post(apiEndPoint, this.state.inputs);
    if (response.status === 200) {
      const { onSubmit } = this.props;
      if (onSubmit) {
        onSubmit(response);
      }
    } else {
      this.onTextErrorListeners(null, "name");
      this.onTextErrorListeners(null, "airport");
      this.onTextErrorListeners(null, "type");
      this.onTextErrorListeners(null, "terminal");
      this.onTextErrorListeners(null, "address");
      this.onTextErrorListeners(null, "reduce_percent");
      this.onTextErrorListeners(null, "accurate_client_id");
      this.onTextErrorListeners(null, "accurate_client_secret");
      this.onTextErrorListeners(null, "api_key");
      this.onTextErrorListeners(null, "api_url");

      if (response.status === 412 && response.results.data !== undefined) {
        if ( response.results.data.name !== undefined && response.results.data.name !== "" ) {
          this.onTextErrorListeners(response.results.data.name, "name");
        }
        if ( response.results.data.airport !== undefined && response.results.data.airport !== "" ) {
          this.onTextErrorListeners(response.results.data.airport, "airport");
        }
        if ( response.results.data.type !== undefined && response.results.data.type !== "" ) {
          this.onTextErrorListeners(response.results.data.type, "type");
        }
        if ( response.results.data.terminal !== undefined && response.results.data.terminal !== "" ) {
          this.onTextErrorListeners(response.results.data.terminal, "terminal");
        }
        if ( response.results.data.address !== undefined && response.results.data.address !== "" ) {
          this.onTextErrorListeners(response.results.data.address, "address");
        }
        if ( response.results.data.reduce_percent !== undefined && response.results.data.reduce_percent !== "" ) {
          this.onTextErrorListeners(response.results.data.reduce_percent, "reduce_percent");
        }
        if ( response.results.data.accurate_client_id !== undefined && response.results.data.accurate_client_id !== "" ) {
          this.onTextErrorListeners( response.results.data.accurate_client_id, "accurate_client_id" );
        }
        if ( response.results.data.accurate_client_secret !== undefined && response.results.data.accurate_client_secret !== "" ) {
          this.onTextErrorListeners( response.results.data.accurate_client_secret, "accurate_client_secret" );
        }
        if ( response.results.data.api_key !== undefined && response.results.data.api_key !== "" ) {
          this.onTextErrorListeners( response.results.data.api_key, "api_key" );
        }
        if ( response.results.data.api_url !== undefined && response.results.data.api_url !== "" ) {
          this.onTextErrorListeners( response.results.data.api_url, "api_url" );
        }
      } else {
        const { onSubmit } = this.props;
        if (onSubmit) {
          onSubmit(response);
        }
      }
    }
    this.ref_LoadingDialog.onCloseDialog();
  };
  onCopyText = (text) => {
    if(navigator !== undefined && navigator.clipboard !== undefined){
      navigator.clipboard.writeText(text)
      this.setState({
        showSnackbar: true,
        severitySnackbar: "success",
        messageSnackbar: "Berhasil salin url webhook.",
      });
    }else{
      this.setState({
        showSnackbar: true,
        severitySnackbar: "error",
        messageSnackbar: "Gagal salin url webhook.",
      })
    }
  }

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  render() {
    let mdlBtnColor = "#e74c3c";
    let mdlBtnIcon = "delete";
    let mdlBtnTitle = "Hapus Data Merchant";
    if (this.state.modalType === "add") {
      mdlBtnColor = "#16a085";
      mdlBtnIcon = "check_circle";
      mdlBtnTitle = "Tambah Data Merchant";
    }
    if (this.state.modalType === "edit") {
      mdlBtnColor = "#e67e22";
      mdlBtnIcon = "edit";
      mdlBtnTitle = "Edit Data Merchant";
    }
    if (this.state.modalType === "detail") {
      mdlBtnColor = "#e67e22";
      mdlBtnIcon = "edit";
      mdlBtnTitle = "Detail Merchant";
    }
    if (this.state.modalType === "activated") {
      if (
        this.state.modalData !== null &&
        this.state.modalData.is_active_bool !== undefined
      ) {
        mdlBtnColor = this.state.modalData.is_active_bool
          ? "#e74c3c"
          : "#27ae60";
        mdlBtnIcon = this.state.modalData.is_active_bool
          ? "block"
          : "check_circle";
        mdlBtnTitle = this.state.modalData.is_active_bool
          ? "Non Aktifkan Merchant"
          : "Aktifkan Merchant";
      }
    }
    return (
      <>
        {/* MAIN MODAL */}
        <Modal
          width={"calc(100% - 40px)"}
          show={this.state.showModal}
          onModalClose={this.onCloseDialog}
          btnCloseTitle={this.state.modalType !== "detail" ? "TIDAK" : "TUTUP"}
          btnCloseHeight={40}
        >
          <ModalTitle>
            <span
              style={{ fontSize: 16, color: "#505050", fontWeight: "bold" }}
            >
              {mdlBtnTitle}
            </span>
          </ModalTitle>
          <ModalBody>
            {this.state.modalData !== null ? (
              <>
                {this.state.modalType === "activated" && (
                  <div style={{ fontSize: 15, lineHeight: 1.5 }}>
                    Apakah Anda yakin ingin{" "}
                    {this.state.modalData.is_active_bool
                      ? "menonaktifkan "
                      : "aktifkan "}
                    merchant <b>{this.state.modalData.name}</b>?
                  </div>
                )}
                {this.state.modalType === "delete" && (
                  <div style={{ fontSize: 15, lineHeight: 1.5 }}>
                    Apakah Anda yakin ingin hapus data merchant{" "}
                    <b>{this.state.modalData.name}</b>?
                  </div>
                )}
                {this.state.modalType === "edit" && this.renderForm()}
                {this.state.modalType === "detail" && this.renderInfo()}
              </>
            ) : (
              this.state.modalType === "add" && this.renderForm()
            )}
          </ModalBody>
          <ModalFooter>
            {this.state.modalType !== "detail" && (
              <div
                className={"form_act_btn"}
                style={{
                  height: 40,
                  color: "#fff",
                  backgroundColor: mdlBtnColor,
                  padding: "0 10px",
                  fontSize: 14,
                  textTransform: "uppercase",
                }}
                onClick={() => {
                  if (this.state.modalType === "activated") {
                    this.onActivatedListeners();
                  }
                  if (this.state.modalType === "delete") {
                    this.onDeleteListeners();
                  }
                  if (
                    this.state.modalType === "add" ||
                    this.state.modalType === "edit"
                  ) {
                    this.onValidateAddEditListeners();
                  }
                }}
              >
                <Icon style={{ fontSize: 16, marginRight: 7.5 }}>
                  {mdlBtnIcon}
                </Icon>
                <div>{mdlBtnTitle}</div>
              </div>
            )}
          </ModalFooter>
        </Modal>

        {/* LOADING DIALOG */}
        <Loading ref={(value) => (this.ref_LoadingDialog = value)} />

        {/* SNACKBAR & ALERT DIALOG */}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          style={{ bottom: 80 }}
          open={this.state.showSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            this.setState({ showSnackbar: false, messageSnackbar: "" });
          }}
        >
          <Alert
            severity={this.state.severitySnackbar}
            onClose={() => {
              this.setState({ showSnackbar: false, messageSnackbar: "" });
            }}
            sx={{ width: "100%" }}
          >
            {this.state.messageSnackbar}
          </Alert>
        </Snackbar>

        {/* DIALOG CONFIRMATION */}
        <Modal
          width={"calc(100% - 60px)"}
          show={this.state.showConfirmation}
          onModalClose={() => {
            this.setState({ showConfirmation: false });
          }}
          btnCloseTitle={this.state.modalType !== "detail" ? "TIDAK" : "TUTUP"}
        >
          <ModalTitle>
            <span
              style={{ fontSize: 16, color: "#505050", fontWeight: "bold" }}
            >
              Konfirmasi
            </span>
          </ModalTitle>
          <ModalBody>
            {this.state.modalData !== null ? (
              <div style={{ fontSize: 15, lineHeight: 1.5 }}>
                Apakah Anda yakin akan <b>{mdlBtnTitle}</b> dengan nama{" "}
                <b>{this.state.modalData.name}</b>?
              </div>
            ) : (
              this.state.modalType === "add" && (
                <div style={{ fontSize: 15, lineHeight: 1.5 }}>
                  Apakah Anda yakin akan <b>{mdlBtnTitle}</b>{" "}
                  <b>{this.state.inputs.name}</b>?
                </div>
              )
            )}
          </ModalBody>
          <ModalFooter>
            <div
              className={"form_act_btn"}
              style={{
                height: 40,
                color: "#fff",
                backgroundColor: "#16a085",
                padding: "0 10px",
                fontSize: 14,
                textTransform: "uppercase",
              }}
              onClick={() => {
                this.setState({ showConfirmation: false });
                if (
                  this.state.modalType === "add" ||
                  this.state.modalType === "edit"
                ) {
                  this.onSubmitAddEditListeners();
                }
              }}
            >
              <Icon style={{ fontSize: 16, marginRight: 7.5 }}>
                check_circle
              </Icon>
              <div>Ya, Lanjutkan Proses</div>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
  renderForm() {
    if (this.state.modalType === "add" || this.state.modalType === "edit") {
      return (
        <>
          {/* ==== NAMA TIPE PELANGGAN */}
          <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
            <span
              style={{
                textAlign: "left",
                fontSize: 13,
                color: "#666",
                marginBottom: 5,
              }}
            >
              Nama Merchant
            </span>
            <OutlinedInput
              id="input-name"
              startAdornment={
                <InputAdornment position="start">
                  <Icon>storefront</Icon>
                </InputAdornment>
              }
              placeholder="Tuliskan nama merchant..."
              value={this.state.inputs.name}
              onChange={(event) => {
                if (
                  event.target !== undefined &&
                  event.target.value !== undefined
                ) {
                  this.onTextInputListeners(event.target.value, "name");
                }
              }}
              error={
                this.state.errors.name !== undefined &&
                this.state.errors.name !== null
                  ? true
                  : false
              }
              onFocus={() => this.onTextErrorListeners(null, "name")}
              sx={{
                "& input::placeholder": { color: "#999" },
                backgroundColor: "#f7f7f7",
              }}
              disabled={this.state.isAddFromExisting ? true : false}
            />
            <InputRequired
              required
              errors={this.state.errors.name}
              size={11}
              label={"Minimal 3 karakter"}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
            <span
              style={{
                textAlign: "left",
                fontSize: 13,
                color: "#666",
                marginBottom: 5,
              }}
            >
              Bandara
            </span>
            <Select
              id="input-airport"
              className={""}
              startAdornment={
                <InputAdornment position="start">
                  <Icon>flight</Icon>
                </InputAdornment>
              }
              placeholder="Pilih opsi disini…"
              value={this.state.inputs.airport}
              onChange={(event) => {
                let inputs = this.state.inputs;
                inputs.airport = event.target.value;
                this.setState({ inputs });
              }}
              sx={{
                "& select::placeholder": { color: "#999" },
                backgroundColor: "#f7f7f7",
              }}
            >
              <MenuItem value="DPS">
                Bandara I Gusti Ngurah Rai - Depasar [DPS]
              </MenuItem>
              <MenuItem value="SUB">Bandara Juanda - Surabaya [SUB]</MenuItem>
              <MenuItem value="UPG">
                Bandara Sultan Hasanuddin - Makassar [UPG]
              </MenuItem>
              <MenuItem value="BPN">
                Bandara Sultan Aji Muhammad Sulaiman Sepinggan - Balikpapan
                [BPN]
              </MenuItem>
              <MenuItem value="BIK">
                Bandara Frans Kaisiepo - Biak [BIK]
              </MenuItem>
              <MenuItem value="MDC">
                Bandara Sam Ratulagi - Manado [MDC]
              </MenuItem>
              <MenuItem value="BDJ">
                Bandara Syamsudin Noor - Banjarmasin [BDJ]
              </MenuItem>
              <MenuItem value="SRG">
                Bandara Jendral Ahmad Yani - Semarang [SRG]
              </MenuItem>
              <MenuItem value="JOG">
                Bandara Adisutjipto - Yogyakarta [JOG]
              </MenuItem>
              <MenuItem value="SOC">
                Bandara Adi Soemarno - Surakarta [SOC]
              </MenuItem>
              <MenuItem value="LOP">
                Bandara Internasional Lombok - Lombok Tengah [LOP]
              </MenuItem>
              <MenuItem value="AMQ">Bandara Pattimura - Ambon [AMQ]</MenuItem>
              <MenuItem value="KOE">Bandara El Tari - Kupang [KOE]</MenuItem>
              <MenuItem value="YIA">
                Bandara Internasional Yogyakarta - Kulon Progo [YIA]
              </MenuItem>
              <MenuItem value="DJJ">Bandara Sentani - Jayapura [DJJ]</MenuItem>
            </Select>
            <InputRequired
              required
              errors={this.state.errors.airport}
              size={11}
              label={"Harus dipilih"}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
            <span
              style={{
                textAlign: "left",
                fontSize: 13,
                color: "#666",
                marginBottom: 5,
              }}
            >
              Tipe
            </span>
            <Select
              id="input-type"
              className={""}
              startAdornment={
                <InputAdornment position="start">
                  <Icon>tag</Icon>
                </InputAdornment>
              }
              placeholder="Pilih opsi disini…"
              value={this.state.inputs.type}
              onChange={(event) => {
                let inputs = this.state.inputs;
                inputs.type = event.target.value;
                this.setState({ inputs });
              }}
              sx={{
                "& select::placeholder": { color: "#999" },
                backgroundColor: "#f7f7f7",
              }}
            >
              <MenuItem value="M01">FnB [M01]</MenuItem>
              <MenuItem value="M02">Retail [M02]</MenuItem>
              <MenuItem value="M03">Service [M03]</MenuItem>
              <MenuItem value="M04">Duty Free [M04]</MenuItem>
              <MenuItem value="M05">Lounge [M05]</MenuItem>
            </Select>
            <InputRequired
              required
              errors={this.state.errors.type}
              size={11}
              label={"Harus dipilih"}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
            <span
              style={{
                textAlign: "left",
                fontSize: 13,
                color: "#666",
                marginBottom: 5,
              }}
            >
              Terminal
            </span>
            <OutlinedInput
              id="input-terminal"
              startAdornment={
                <InputAdornment position="start">
                  <Icon>apartment</Icon>
                </InputAdornment>
              }
              placeholder="Tuliskan terminal..."
              value={this.state.inputs.terminal}
              onChange={(event) => {
                if (
                  event.target !== undefined &&
                  event.target.value !== undefined
                ) {
                  this.onTextInputListeners(event.target.value, "terminal");
                }
              }}
              error={
                this.state.errors.terminal !== undefined &&
                this.state.errors.terminal !== null
                  ? true
                  : false
              }
              onFocus={() => this.onTextErrorListeners(null, "terminal")}
              sx={{
                "& input::placeholder": { color: "#999" },
                backgroundColor: "#f7f7f7",
              }}
              disabled={this.state.isAddFromExisting ? true : false}
            />
            <InputRequired
              required
              errors={this.state.errors.terminal}
              size={11}
              label={"Harus diisi"}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
            <span
              style={{
                textAlign: "left",
                fontSize: 13,
                color: "#666",
                marginBottom: 5,
              }}
            >
              Alamat
            </span>
            <OutlinedInput
              id="input-address"
              startAdornment={
                <InputAdornment position="start">
                  <Icon>map</Icon>
                </InputAdornment>
              }
              placeholder="Tuliskan address..."
              value={this.state.inputs.address}
              onChange={(event) => {
                if (
                  event.target !== undefined &&
                  event.target.value !== undefined
                ) {
                  this.onTextInputListeners(event.target.value, "address");
                }
              }}
              error={
                this.state.errors.address !== undefined &&
                this.state.errors.address !== null
                  ? true
                  : false
              }
              onFocus={() => this.onTextErrorListeners(null, "address")}
              sx={{
                "& input::placeholder": { color: "#999" },
                backgroundColor: "#f7f7f7",
              }}
              disabled={this.state.isAddFromExisting ? true : false}
            />
            <InputRequired
              required
              errors={this.state.errors.address}
              size={11}
              label={"Harus diisi"}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
            <span
              style={{
                textAlign: "left",
                fontSize: 13,
                color: "#666",
                marginBottom: 5,
              }}
            >
              Persen Pengurang Nilai Transaksi
            </span>
            <OutlinedInput
              id="input-reduce_percent"
              startAdornment={
                <InputAdornment position="start">
                  <Icon>percent</Icon>
                </InputAdornment>
              }
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*", min: 0, max: 100, allowNegative: false, }}
              placeholder="Tuliskan persen pengurang nilai transaksi..."
              value={this.state.inputs.reduce_percent}
              onChange={(event) => {
                if ( event.target !== undefined && event.target.value !== undefined ) {
                  this.onTextInputListeners(event.target.value, "reduce_percent");
                }
              }}
              inputComponent={TextFieldNumberFormat}
              error={
                this.state.errors.reduce_percent !== undefined &&
                this.state.errors.reduce_percent !== null
                  ? true
                  : false
              }
              onFocus={() => this.onTextErrorListeners(null, "reduce_percent")}
              sx={{
                "& input::placeholder": { color: "#999" },
                backgroundColor: "#f7f7f7",
              }}
              disabled={this.state.isAddFromExisting ? true : false}
            />
            <InputRequired
              required
              errors={this.state.errors.reduce_percent}
              size={11}
              label={"Harus diisi"}
            />
          </FormControl>

          {/* ==== DESKRIPSI TIPE PELANGGAN */}
          <FormControl fullWidth variant="outlined" style={{ marginTop: 10 }}>
            <span
              style={{
                textAlign: "left",
                fontSize: 13,
                color: "#666",
                marginBottom: 5,
              }}
            >
              Accurate Client ID
            </span>
            <OutlinedInput
              id="input-accurate_client_id"
              startAdornment={
                <InputAdornment position="start">
                  <Icon>fingerprint</Icon>
                </InputAdornment>
              }
              placeholder="Tuliskan client ID..."
              value={this.state.inputs.accurate_client_id}
              onChange={(event) => {
                if (
                  event.target !== undefined &&
                  event.target.value !== undefined
                ) {
                  this.onTextInputListeners(
                    event.target.value,
                    "accurate_client_id"
                  );
                }
              }}
              error={
                this.state.errors.accurate_client_id !== undefined &&
                this.state.errors.accurate_client_id !== null
                  ? true
                  : false
              }
              onFocus={() =>
                this.onTextErrorListeners(null, "accurate_client_id")
              }
              sx={{
                "& input::placeholder": { color: "#999" },
                backgroundColor: "#f7f7f7",
              }}
              disabled={this.state.isAddFromExisting ? true : false}
            />
            <InputRequired
              required
              errors={this.state.errors.accurate_client_id}
              size={11}
              label={"Harus diisi"}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" style={{ marginTop: 10 }}>
            <span
              style={{
                textAlign: "left",
                fontSize: 13,
                color: "#666",
                marginBottom: 5,
              }}
            >
              Accurate Client Secret
            </span>
            <OutlinedInput
              id="input-accurate_client_secret"
              startAdornment={
                <InputAdornment position="start">
                  <Icon>password</Icon>
                </InputAdornment>
              }
              placeholder="Tuliskan client secret..."
              value={this.state.inputs.accurate_client_secret}
              onChange={(event) => {
                if (
                  event.target !== undefined &&
                  event.target.value !== undefined
                ) {
                  this.onTextInputListeners(
                    event.target.value,
                    "accurate_client_secret"
                  );
                }
              }}
              error={
                this.state.errors.accurate_client_secret !== undefined &&
                this.state.errors.accurate_client_secret !== null
                  ? true
                  : false
              }
              onFocus={() =>
                this.onTextErrorListeners(null, "accurate_client_secret")
              }
              sx={{
                "& input::placeholder": { color: "#999" },
                backgroundColor: "#f7f7f7",
              }}
              disabled={this.state.isAddFromExisting ? true : false}
            />
            <InputRequired
              required
              errors={this.state.errors.accurate_client_secret}
              size={11}
              label={"Harus diisi"}
            />
          </FormControl>


          <FormControl fullWidth variant="outlined" style={{ marginTop: 10 }}>
            <span
              style={{
                textAlign: "left",
                fontSize: 13,
                color: "#666",
                marginBottom: 5,
              }}
            >
              API Key
            </span>
            <OutlinedInput
              id="input-api_key"
              startAdornment={
                <InputAdornment position="start">
                  <Icon>key</Icon>
                </InputAdornment>
              }
              placeholder="Tuliskan client secret..."
              value={this.state.inputs.api_key}
              onChange={(event) => {
                if (
                  event.target !== undefined &&
                  event.target.value !== undefined
                ) {
                  this.onTextInputListeners(
                    event.target.value,
                    "api_key"
                  );
                }
              }}
              error={
                this.state.errors.api_key !== undefined &&
                this.state.errors.api_key !== null
                  ? true
                  : false
              }
              onFocus={() =>
                this.onTextErrorListeners(null, "api_key")
              }
              sx={{
                "& input::placeholder": { color: "#999" },
                backgroundColor: "#f7f7f7",
              }}
              disabled={this.state.isAddFromExisting ? true : false}
            />
            <InputRequired
              required
              errors={this.state.errors.api_key}
              size={11}
              label={"Harus diisi"}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" style={{ marginTop: 10 }}>
            <span
              style={{
                textAlign: "left",
                fontSize: 13,
                color: "#666",
                marginBottom: 5,
              }}
            >
              API Url
            </span>
            <OutlinedInput
              id="input-api_url"
              startAdornment={
                <InputAdornment position="start">
                  <Icon>link</Icon>
                </InputAdornment>
              }
              placeholder="Tuliskan client secret..."
              value={this.state.inputs.api_url}
              onChange={(event) => {
                if (
                  event.target !== undefined &&
                  event.target.value !== undefined
                ) {
                  this.onTextInputListeners(
                    event.target.value,
                    "api_url"
                  );
                }
              }}
              error={
                this.state.errors.api_url !== undefined &&
                this.state.errors.api_url !== null
                  ? true
                  : false
              }
              onFocus={() =>
                this.onTextErrorListeners(null, "api_url")
              }
              sx={{
                "& input::placeholder": { color: "#999" },
                backgroundColor: "#f7f7f7",
              }}
              disabled={this.state.isAddFromExisting ? true : false}
            />
            <InputRequired
              required
              errors={this.state.errors.api_url}
              size={11}
              label={"Harus diisi"}
            />
          </FormControl>
        </>
      );
    }
  }

  renderInfo(){
    return (
      <>
        <div style={{...styles.container, paddingTop: 0}}>
          <div style={styles.title}>Nama Merchant</div>
          <div style={styles.body}>{this.state.modalData.name}</div>
        </div>
        <div style={styles.container}>
          <div style={styles.title}>Bandara</div>
          <div style={styles.body}>{this.state.modalData.airport}</div>
        </div>
        <div style={styles.container}>
          <div style={styles.title}>Tipe</div>
          <div style={styles.body}>{this.state.modalData.type}</div>
        </div>
        <div style={styles.container}>
          <div style={styles.title}>Terminal</div>
          <div style={styles.body}>{this.state.modalData.terminal}</div>
        </div>
        <div style={styles.container}>
          <div style={styles.title}>Alamat</div>
          <div style={styles.body}>{this.state.modalData.address}</div>
        </div>
        <div style={styles.container}>
          <div style={styles.title}>Persen Pengurang Nilai Transaksi</div>
          <div style={styles.body}>{this.state.modalData.reduce_percent}%</div>
        </div>
        <div style={styles.container}>
          <div style={styles.title}>Accurate Client ID</div>
          <div style={styles.body}>{this.state.modalData.accurate_client_id}</div>
        </div>
        <div style={styles.container}>
          <div style={styles.title}>Accurate Client Secret</div>
          <div style={styles.body}>{this.state.modalData.accurate_client_secret}</div>
        </div>
        <div style={{...styles.container}}>
          <div style={styles.title}>
            URL Webhook
            <Tooltip title={'Salin URL Webhook'}>
              <IconButton style={{ marginLeft: 10}} onClick={() => {this.onCopyText(`${Constants.accurateWebhookBaseURL}?clientID=${this.state.modalData.accurate_client_id}`)}}>
                <Icon style={{ color: 'var(--primary-color)', fontSize: 16}}>content_copy</Icon>
              </IconButton>
            </Tooltip>
          </div>
          <div style={styles.body}>{`${Constants.accurateWebhookBaseURL}?clientID=${this.state.modalData.accurate_client_id}`}</div>
        </div>
        <div style={styles.container}>
          <div style={styles.title}>API Key</div>
          <div style={styles.body}>{this.state.modalData.api_key}</div>
        </div>
        <div style={{...styles.container, borderBottom: 'none'}}>
          <div style={styles.title}>API Url</div>
          <div style={styles.body}>{this.state.modalData.api_url}</div>
        </div>
        
      </>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0px',
    borderBottom: 'solid 1px rgba(0,0,0,0.1)',
  },
  title: {
    fontSize: 12
  },
  body: {
    fontSize: 14,
    fontWeight: '500'
  }
  
}
