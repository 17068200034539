/*
Created by esoda
Created on Nov, 2023
Contact esoda.id
*/

import React from "react";
import { Modal, Box } from "@mui/material";

export default class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showDialog: false };
  }

  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================
  onShowDialog = () => {
    this.setState({ showDialog: true });
  };
  onCloseDialog = () => {
    this.setState({ showDialog: false });
  };

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  render() {
    return (
      <Modal open={this.state.showDialog} onClose={this.onCloseDialog}>
        <Box
          sx={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            width: "100wh",
            height: "100vh",
          }}
        >
          <div
            style={{
              width: 80,
              height: 80,
              borderRadius: 40,
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
              position: "relative",
            }}
          >
            <img
              src="/assets/animations/loading.gif"
              alt="Loading"
              width={75}
              height={75}
              priority
            />
            <div
              style={{
                display: 'none',
                position: "absolute",
                width: 35,
                height: 35,
                overflow: "hidden",
                left: 22.5,
                top: 22.5,
              }}
            >
              <img
                src="/assets/images/img_loading_sodapos.png"
                alt="sodapos.com"
                width={35}
                height={35}
                priority
              />
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}
