import './App.css'
import "styles/Main.css";
import * as Middleware from './middleware/Middleware'
import 'moment'
import 'moment/locale/id'
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom'
import Dashboard from './pages/dashboard'
import AccountProfile from './pages/account/profile'
import Login from './pages/login'
import Custom4040 from 'pages/404'
import SyncData from 'pages/sync-data'
import Merchant from 'pages/merchant'
import OauthCallback from 'pages/oauth/callback';


// const titles = {
//   '/': 'Login',
//   '/dashboard': 'Dashboard',
// }

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route index exact path="/" element={<Middleware.Guest render={<Login />} />} />
        <Route path="/oauth/callback" element={<Middleware.Authenticated render={<OauthCallback />} />} />
        <Route exact path="/dashboard" element={<Middleware.Authenticated render={<Dashboard />} />} />
        <Route exact path="/account/profile" element={<Middleware.Authenticated render={<AccountProfile />} />} />
        <Route exact path="/sync-data" element={<Middleware.Authenticated render={<SyncData />} />} />
        <Route exact path="/merchant" element={<Middleware.Authenticated render={<Merchant />} />} />

        <Route path='*' element={<Custom4040 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
