import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { userAuth } from "../store"

function Authenticated(props) {
    const navigate = useNavigate();
    const auth = userAuth()

    useEffect(() => {
        if(auth === null) {
            setTimeout(() => {
                navigate('/')
            }, 100)
        } else if(auth.id === undefined ){
            setTimeout(() => {
                navigate('/')
            }, 100)
        }
    })

    return props.render
}

export default Authenticated