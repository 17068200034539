/*
Created by esoda
Created on Nov, 2023
Contact esoda.id
Last modified: -
    - Read changelog.md
*/

import React from "react";
import "styles/MyTable.css";
import CommonHelper from "utils/CommonHelper";
import { Icon, Tooltip } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export default class MyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputPage: "",
      inputSearch: "",
    };
  }

  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================
  onGoToPageListeners = (event) => {
    if (event.keyCode === 13) {
      if (this.state.inputPage !== "") {
        this.onClickPageListeners(this.state.inputPage);
      }

      // IE 9, 10
      event.preventDefault();
    }
  };
  onClickPageListeners = (page) => {
    this.setState({ inputPage: "" });
    const { onPageListeners } = this.props;
    onPageListeners(page);
  };
  onSetHeading = (arrHeading) => {
    this.setState({ arrHeading });
  };
  onSortDataListeners = (item, index) => {
    let arrHeading = this.props.arrHeading;

    let inputSort = "";
    if (item.sort === "") {
      item.sort = "asc";
      inputSort = item.key;
    } else if (item.sort === "asc") {
      item.sort = "desc";
      inputSort = `-${item.key}`;
    } else {
      item.sort = "";
    }

    arrHeading[index] = item;
    arrHeading.map((val) => {
      if (val.sortable && val.key !== item.key) {
        val.sort = "";
      }
      return val;
    });

    this.setState({ arrHeading });
    const { onSort } = this.props;
    if (onSort !== undefined) {
      onSort(inputSort);
    }
  };
  setSearchValue = (val) => {
    this.setState({ inputSearch: val });
  };

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  render() {
    let padding_top20 = {};
    if (
      this.props.summaryView === undefined &&
      this.props.inputSearch !== undefined &&
      this.props.inputSearch !== ""
    ) {
      padding_top20 = 'padding_top20';
    }
    if (
      this.props.summaryView === undefined &&
      this.props.inputFilter !== undefined &&
      this.props.inputFilter.length > 0
    ) {
      padding_top20 = 'padding_top20';
    }
    let showSearch = true;
    let styleFilter = {};
    if (this.props.showSearch !== undefined) {
      showSearch = this.props.showSearch;
    }
    if (!showSearch) {
      styleFilter = {
        marginLeft: 0,
      };
    }

    return (
      <div class={`table ${this.props.addTableClass || ''}`}>
        <div class={`header`}>
          {this.props.summaryView && (
            <div className="summary">{this.props.summaryView}</div>
          )}

          <div
            className={`title`}
            style={{
              marginBottom: this.props.disableSearch !== undefined ? 20 : 0,
            }}
          >
            {this.props.title}
          </div>

          {this.props.disableSearch === undefined && (
            <div
              className={
                this.props.summaryView !== undefined
                  ? "search"
                  : `search no_padding`
              }
            >
              {showSearch && (
                <div className={`form`}>
                  <Icon className="icon">search</Icon>
                  <input
                    className="input"
                    placeholder={this.props.placeholderSearch}
                    value={this.state.inputSearch}
                    onChange={(event) => {
                      if (
                        event.target !== undefined &&
                        event.target.value !== undefined
                      ) {
                        this.setState({ inputSearch: event.target.value });
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (this.state.inputSearch) {
                          this.props.onSearch(this.state.inputSearch);
                        }
                      }
                      if (e.key === "Delete" || e.key === "Backspace") {
                        if (this.state.inputSearch.length === 1) {
                          this.props.onSearch("");
                        }
                      }
                    }}
                  />
                  {this.state.inputSearch.length > 0 && (
                    <Tooltip title={"Kosongkan Area Pencarian"}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.setState(
                            { inputSearch: "" },
                            this.props.onSearch("")
                          );
                        }}
                      >
                        <Icon
                          style={{
                            fontSize: "20px",
                            color: "red",
                            marginTop: "5px",
                            marginRight: "-3px",
                          }}
                        >
                          close
                        </Icon>
                      </span>
                    </Tooltip>
                  )}
                </div>
              )}

              {this.props.showFilter && (
                <Tooltip title={"Filter Data"}>
                  <div
                    className="filter"
                    style={styleFilter}
                    onClick={() => {
                      this.props.onFilter();
                    }}
                  >
                    <Icon>tune</Icon>
                    <span className="label">Filter Data</span>
                  </div>
                </Tooltip>
              )}

              {this.props.showAdd && (
                <Tooltip
                  title={
                    this.props.addText ? this.props.addText : "Tambah Data"
                  }
                >
                  <div
                    className="add"
                    onClick={() => {
                      this.props.onAdd();
                    }}
                  >
                    {this.props.addIcon !== undefined ? (
                      this.props.addIcon
                    ) : (
                      <Icon>add</Icon>
                    )}
                    <span className="label">
                      {this.props.addText ? this.props.addText : "Tambah Data"}
                    </span>
                  </div>
                </Tooltip>
              )}

              {this.props.showCustomButton && this.props.renderCustomeButton}

              <div
                className={
                  this.props.showDownload || this.props.showShare
                    ? `button_list margin_button_list`
                    : `button_list`
                }
              >
                {this.props.showDownload && (
                  <Tooltip title={"Download File"}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.props.onDownload();
                      }}
                    >
                      <Icon style={{ fontSize: "20px" }}>download</Icon>
                    </span>
                  </Tooltip>
                )}
                {this.props.showShare && (
                  <Tooltip title={"Bagikan File Ini"}>
                    <span
                      style={{ cursor: "pointer", marginLeft: "15px" }}
                      onClick={() => {
                        this.props.onShare();
                      }}
                    >
                      <Icon style={{ fontSize: "20px" }}>share</Icon>
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
          )}

          <div className={`placeholder ${padding_top20}`}>
            {this.props.inputSearch && this.props.inputSearch !== "" && (
              <div className="item" style={{ marginRight: 10 }}>
                <span style={{ marginLeft: "5px" }}>
                  {this.props.inputSearch}
                </span>
                <Tooltip title={"Hapus pencarian ini"}>
                  <span
                    onClick={() => {
                      this.props.onClearSearch();
                    }}
                  >
                    <Icon>cancel</Icon>
                  </span>
                </Tooltip>
              </div>
            )}
            {this.props.inputFilter &&
              this.props.inputFilter.length > 0 &&
              this.props.inputFilter.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="item"
                    style={{
                      backgroundColor: "#3498db",
                      marginLeft: index > 0 ? 10 : 0,
                    }}
                  >
                    <span
                      style={{ marginLeft: "5px", textTransform: "capitalize" }}
                    >
                      {item.title || item.value}
                    </span>
                    <Tooltip title={"Hapus filter ini"}>
                      <span
                        onClick={() => {
                          this.props.onRemoveFilterItem(index);
                        }}
                      >
                        <Icon>cancel</Icon>
                      </span>
                    </Tooltip>
                  </div>
                );
              })}
          </div>
        </div>
        <div class={`content`}>
          <table cellpadding="0" cellspacing="0">
            <thead>{this.renderHeading(this.props)}</thead>
            {this.renderItems()}
          </table>
          {this.renderEmpty()}
        </div>
        {this.renderLoadmore(this.props)}
        {this.props.fetchDataPagination !== undefined &&
          this.props.fetchDataPagination !== null && (
            <div className="footer">{this.renderPagination(this.props)}</div>
          )}
      </div>
    );
  }
  renderHeading(props) {
    let arrData = this.props.arrHeading;
    if (arrData.length > 0) {
      return (
        <tr>
          {props.useChecklist && (
            <th className="checklist_item_th">
              <span>
                <Icon>check_box_outline_blank</Icon>
              </span>
            </th>
          )}
          {arrData.map((item, index) => {
            return (
              <th
                key={index}
                className={item.className !== undefined && item.className}
              >
                {/* {item.label} */}
                <span
                  key={index}
                  className={`items ${
                    item.sortable && item.sort && "active_main_grid_header_item"
                  }`}
                  style={item.styles}
                  onClick={() => {
                    if (item.sortable) {
                      this.onSortDataListeners(item, index);
                    }
                  }}
                >
                  <span style={{ marginRight: item.sortable ? 30 : 0 }}>
                    {item.label}
                  </span>
                  {item.sortable && item.sort && (
                    <span style={{ marginRight: -5 }}>
                      {item.sort === "asc" ? (
                        <Icon style={{ fontSize: 16 }}>arrow_upward</Icon>
                      ) : (
                        <Icon style={{ fontSize: 16 }}>arrow_downward</Icon>
                      )}
                    </span>
                  )}
                  {item.sortable && !item.sort && (
                    <span style={{ marginRight: -5 }}>
                      <Icon style={{ fontSize: 16 }}>sort_by_alpha</Icon>
                    </span>
                  )}
                </span>
              </th>
            );
          })}
        </tr>
      );
    }
  }
  renderLoadmore(props) {
    if (!props.isFetched) {
      if (
        props.fetchDataPagination !== undefined &&
        props.fetchDataPagination !== null &&
        props.fetchDataPagination.total_data !== undefined &&
        props.fetchDataPagination.total_data !== null &&
        props.fetchDataPagination.total_data > 0
      ) {
        if (Number(props.fetchDataPagination.next) > 0) {
          return (
            <div
              className='loadmore'
              onClick={() => {
                const { onLoadMore } = props;
                if (onLoadMore) {
                  onLoadMore();
                }
              }}
            >
              Tampilkan Lainnya
            </div>
          );
        }
      }
    }
  }
  renderPagination(props) {
    if (props.isFetched) {
      return (
        <div className='loading'>
          <img
            src="/assets/animations/anim_loading_1.gif"
            alt="Loading"
            width={16}
            height={16}
            priority
            style={{}}
          />
          <span style={{ marginLeft: "10px" }}>Loading data...</span>
        </div>
      );
    } else {
      if (
        props.fetchDataPagination !== undefined &&
        props.fetchDataPagination !== null &&
        props.fetchDataPagination.total_data !== undefined &&
        props.fetchDataPagination.total_data !== null &&
        props.fetchDataPagination.total_data > 0
      ) {
        return (
          <>
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Tooltip title={"Segarkan data table"}>
                <span
                  style={{ marginTop: 3, cursor: "pointer", marginRight: 15 }}
                  onClick={() => {
                    const { onReload } = props;
                    if (onReload) {
                      onReload();
                    }
                  }}
                >
                  <Icon style={{ fontSize: 24, color: "#27ae60" }}>
                    autorenew
                  </Icon>
                </span>
              </Tooltip>
              {props.isLoadPage ? (
                <span className='placeholder'>Loading...</span>
              ) : (
                <span className={"placeholder"}>
                  Data ke{" "}
                  <b>
                    {CommonHelper.formatNumber(
                      props.fetchDataPagination.start,
                      ""
                    )}
                  </b>{" "}
                  -{" "}
                  <b>
                    {CommonHelper.formatNumber(
                      props.fetchDataPagination.end,
                      ""
                    )}
                  </b>{" "}
                  dari{" "}
                  <b>
                    {CommonHelper.formatNumber(
                      props.fetchDataPagination.total_data
                    )}
                  </b>{" "}
                  data
                </span>
              )}
            </span>
            {this.renderPaginationItems(props.fetchDataPagination)}
          </>
        );
      } else {
        return (
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Tooltip title={"Segarkan data table"}>
              <span
                style={{ marginTop: 3, cursor: "pointer", marginRight: 15 }}
                onClick={() => {
                  const { onReload } = props;
                  if (onReload) {
                    onReload();
                  }
                }}
              >
                <Icon style={{ fontSize: 24, color: "#27ae60" }}>
                  autorenew
                </Icon>
              </span>
            </Tooltip>
            <span className='placeholder'>
              Tidak ada data ditampilkan
            </span>
          </span>
        );
      }
    }
  }
  renderPaginationItems(objPage) {
    let arrPage = objPage.detail;
    let arrPageShow = [];

    if (objPage.total_page > 4) {
      let currentPage = objPage.current;
      let totalcount = objPage.total_page;
      // Always print first page button
      arrPageShow.push(1);

      // Print "..." only if currentPage is > 3
      if (currentPage > 3) {
        arrPageShow.push(".....");
      }

      // special case where last page is selected...
      if (currentPage === totalcount) {
        arrPageShow.push(currentPage - 2);
      }

      // Print previous number button if currentPage > 2
      if (currentPage > 2) {
        arrPageShow.push(currentPage - 1);
      }

      //Print current page number button as long as it not the first or last page
      if (currentPage !== 1 && currentPage !== totalcount) {
        arrPageShow.push(currentPage);
      }

      //print next number button if currentPage < lastPage - 1
      if (currentPage < totalcount - 1) {
        arrPageShow.push(currentPage + 1);
      }

      // special case where first page is selected...
      if (currentPage === 1) {
        arrPageShow.push(currentPage + 2);
      }

      //print "..." if currentPage is < lastPage -2
      if (currentPage < totalcount - 2) {
        arrPageShow.push(".....");
      }

      //Always print last page button if there is more than 1 page
      arrPageShow.push(totalcount);
    } else {
      arrPageShow = arrPage;
    }

    let arrPageOption = [];
    if (objPage.total_page > 3) {
      for (let idx = 0; idx < objPage.total_page; idx++) {
        arrPageOption.push(idx + 1);
      }
    }

    if (objPage !== null) {
      return (
        <span className="pages">
          {arrPageShow.length > 0 && (
            <>
              <span
                className="button"
                style={{
                  opacity: objPage.first_page ? 1.0 : 0.2,
                }}
                onClick={() => {
                  if (objPage.first_page) {
                    this.onClickPageListeners(1);
                  }
                }}
              >
                <Icon>skip_previous</Icon>
              </span>
              <span
                className="button"
                style={{
                  opacity: objPage.prev > 0 ? 1.0 : 0.2,
                }}
                onClick={() => {
                  if (objPage.prev > 0) {
                    this.onClickPageListeners(objPage.prev);
                  }
                }}
              >
                <Icon>fast_rewind</Icon>
              </span>
            </>
          )}

          {arrPageShow.length > 0 ? (
            arrPageShow.map((item, key) => {
              if (item !== ".....") {
                return (
                  <span
                    key={key}
                    className="page"
                    style={{
                      border:
                        item === objPage.current
                          ? "none"
                          : "solid 1px rgba(0,0,0,0.2)",
                      color:
                        item === objPage.current ? "#fff" : "rgba(0,0,0,0.4)",
                      backgroundColor:
                        item === objPage.current ? "#555" : "#fff",
                    }}
                    onClick={() => {
                      this.onClickPageListeners(item);
                    }}
                  >
                    {CommonHelper.formatNumber(item, "")}
                  </span>
                );
              } else {
                return (
                  <div key={key} className="divider">
                    .....
                  </div>
                );
              }
            })
          ) : (
            <span className="page">{objPage.current}</span>
          )}

          {arrPageShow.length > 0 && (
            <>
              <span
                className="button"
                style={{
                  opacity: objPage.next > 0 ? 1.0 : 0.2,
                }}
                onClick={() => {
                  if (objPage.next > 0) {
                    this.onClickPageListeners(objPage.next);
                  }
                }}
              >
                <Icon>fast_forward</Icon>
              </span>
              <span
                className="button"
                style={{
                  opacity: objPage.last_page ? 1.0 : 0.2,
                }}
                onClick={() => {
                  this.onClickPageListeners(objPage.total_page);
                }}
              >
                <Icon>skip_next</Icon>
              </span>
            </>
          )}

          {objPage.total_page > 3 && arrPageOption.length > 0 && (
            <span className="options">
              <span className="placeholder">Ke Halaman:</span>
              <input
                className="input"
                type="number"
                placeholder={"Hal.."}
                value={this.state.inputPage}
                onChange={(event) => {
                  if (
                    event.target !== undefined &&
                    event.target.value !== undefined
                  ) {
                    let inputPage = event.target.value;
                    if (inputPage !== "") {
                      if (
                        Number(inputPage) >= 1 &&
                        Number(inputPage) <= objPage.total_page
                      ) {
                        this.setState({ inputPage });
                      }
                    } else {
                      this.setState({ inputPage });
                    }
                  }
                }}
                onKeyDown={(event) => this.onGoToPageListeners(event)}
              />
            </span>
          )}
        </span>
      );
    }
  }
  renderEmpty() {
    if (!this.props.isFetched) {
      if (this.props.dataTable.length <= 0) {
        return (
          <div className='empty_container'>
            <img
              className=""
              src="/assets/images/img_empty_data.png"
              alt="esoftdream.net"
              width={150}
              height={150}
              priority
            />
            <h3 style={{ marginTop: "25px", fontSize: "16px" }}>
              Tidak Ada Data
            </h3>
            <p
              style={{
                marginTop: "5px",
                fontSize: 14,
                lineHeight: 1.5,
                color: "rgba(0,0,0,0.5)",
              }}
            >
              Tidak ada {this.props.title}. Atau data yang Anda cari tidak
              ditemukan.
            </p>
          </div>
        );
      }
    }
  }
  renderItems() {
    if (this.props.isFetched || this.props.isLoadPage) {
      let arrHeading = this.props.arrHeading;
      return (
        <tbody>
          {[1, 2, 3].map((_, index) => {
            return (
              <tr key={index}>
                {arrHeading.map((_, key) => {
                  return (
                    <td key={key}>
                      <div className={`list_item`}>
                        <div className='title'>
                          <Skeleton
                            variant="rounded"
                            width={80}
                            height={16}
                            style={{ marginTop: 0 }}
                          />
                        </div>
                        <div className='value'>
                          <Skeleton
                            variant="rounded"
                            width={100}
                            height={16}
                            style={{ marginTop: 0 }}
                          />
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      );
    } else {
      let arrData = this.props.dataTable;
      if (arrData.length > 0) {
        return (
          <tbody>
            {arrData.map((item, index) => {
              if (this.props.renderItems) {
                return this.props.renderItems(item, index);
              }
              return <></>
            })}
          </tbody>
        );
      }
    }
  }
}