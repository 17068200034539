
import { Box, Container, FormControl, IconButton, InputAdornment, TextField, Icon } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React from "react";
import ApiHelpers from "utils/ApiHelpers";
import FormInputFooter from "components/FormInputFooter";
import { setUserAuth } from "store";
import Constants from "utils/Constant";

export default class Login extends React.Component {

  constructor(props) {
    super(props)
  
    this.state = {
      showPassword: false,
      inputs: { username: '', password: '' },
      errors: {},
      loadingLogin: false
    }
    this.firstMount = true
  }

  componentDidMount(){
    if(this.firstMount){
      this.firstMount = false
      document.title = `Login | ${Constants.appName}`
    }
  }

  componentWillUnmount() { }

  // ====================================================================================
  // ========== INITIALIZE, GET DATA ====================================================
  // ====================================================================================

  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================
  onShowPassword = () => { this.setState({ showPassword: !this.state.showPassword }) }
  onTextInputListeners = (text, input) => {
    let inputs = this.state.inputs
    inputs[input] = text
    this.setState(prevState => ({ ...prevState, inputs }))
  }
  onTextErrorListeners = (error, input) => {
    let errors = this.state.errors
    errors[input] = error
    this.setState(prevState => ({ ...prevState, errors }))
  }
  onNextInputListeners = (e, current, next) => {
    this.onTextErrorListeners(null, current)
    if (e.key === 'Enter') {
      if (next === "SUBMIT") {
        this.onValidateInputListeners()
      } else {
        if (!this.state.inputs[current]) {
          this.onTextErrorListeners('Harus diisi', current);
        } else {
          document.getElementById(next).focus()
        }
      }
    }
  }
  onValidateInputListeners = () => {
    let isValid = true

    if (!this.state.inputs.username) {
      this.onTextErrorListeners('Tidak boleh kosong', 'username');
      isValid = false
    }

    if (!this.state.inputs.password) {
      this.onTextErrorListeners('Tidak boleh kosong', 'password');
      isValid = false
    }

    if (isValid) {
      let params = {
        username: this.state.inputs.username,
        password: this.state.inputs.password
      }
      this.onLoginListeners(params)
    }
  }
  onLoginListeners = async (params) => {
    this.setState({ loadingLogin: true })

    let response = await ApiHelpers.post('auth/login', {
      username: params.username,
      password: params.password,
      device: 'web'
    })

    if (response.status === 200) {
      this.setState({ loadingLogin: false })
      setUserAuth(response.results.data)
      window.location.reload()
    //   Router.replace({
    //     pathname: Constants.webUrl.home,
    //     query: {}
    //   }, Constants.webUrl.home)
    } else {
      this.setState({ loadingLogin: false })
      this.onTextErrorListeners(null, "username")
      this.onTextErrorListeners(null, "password")
      if (response.status === 400) {
        this.onTextErrorListeners(response.message, "username")
        this.onTextErrorListeners(response.message, "password")
      }
    }
  }

 
  
  // ====================================================================================
  // ========== RENDER SECTION ==========================================================
  // ====================================================================================
  render(){
    return (
      <>
        <div style={{
          height: '100vh',
          width: '100vw',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          //background: 'rgb(147, 52, 246)',
          //background: '-moz-linear-gradient(90deg, rgba(147, 52, 246, 1) 0%, rgba(29, 33, 124, 1) 100%)',
          //background: '-webkit-linear-gradient(90deg, rgba(147, 52, 246, 1) 0%, rgba(29, 33, 124, 1) 100%)',
          background: 'linear-gradient(90deg, rgba(147, 52, 246, 1) 0%, rgba(29, 33, 124, 1) 100%)',
          filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#9334f6",endColorstr="#1d217c",GradientType=1)',
        }}>
          <Container maxWidth={'md'} sx={{
            px: {
              sm: 30,
              xs: 20,
            }
          }}>
            <div style={{
              width: '100%',
              height: 600,
              backgroundColor: '#fff',
              boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              overflow: 'hidden',
              borderRadius: 50,
            }}>
              <Box sx={{
                display: { xs: 'none', sm: 'flex', md: 'flex' },
                // background: 'rgb(172,91,255)',
                background: 'linear-gradient(-149deg, rgba(172,91,255,1) 0%, rgba(68,70,172,1) 100%)',
                width: { xs: '100%', sm: '50%', md: '55%' },
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <img src="/assets/images/image-login.png" alt="LogoLogin" style={{ width: '80%', objectFit: 'contain' }}>
                </img>

              </Box>
              <Box sx={{
                display: { md: 'block', sm: 'block', xs: 'none' },
                width: 10,
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.1)',
                zIndex: 10,
              }}>

              </Box>
              <Box sx={{
                width: { xs: '100%', sm: '50%', md: '45%' },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}>
                <Box sx={{
                  padding: '10px 25px 10px 25px',
                  marginTop: 20,
                  marginLeft: { md: -10, sm: -10, xs: 0 },
                  // background: 'rgb(172,91,255)',
                  background: 'linear-gradient(-149deg, rgba(172,91,255,1) 0%, rgba(68,70,172,1) 100%)',
                  color: '#fff',
                  fontSize: 22,
                  width: 'fit-content',
                  borderRadius: '0px 30px 30px 0px'
                }}>
                  <p>Welcome Back</p>
                </Box>
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px 30px',
                  marginTop: 30,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <h2>Kokapura Avia</h2>
                  <h3 style={{ marginBottom: 10 }}>Accurate Webhook</h3>
                  <h5>Login</h5>
                  <FormControl fullWidth variant="standard" style={{ marginTop: 25 }}>
                    <TextField
                      id="input-text-username"
                      variant="standard"
                      type="text"
                      disabled={this.state.loadingLogin}
                      label={`Username`}
                      placeholder='Tuliskan username Anda'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"><Icon>person</Icon></InputAdornment>
                        ),
                      }}
                      value={this.state.inputs.username}
                      onChange={(event) => {
                        if (event.target !== undefined && event.target.value !== undefined) {
                          this.onTextInputListeners(event.target.value, 'username')
                        }
                      }}
                      error={this.state.errors.username !== undefined && this.state.errors.username !== null ? true : false}
                      onFocus={() => this.onTextErrorListeners(null, 'username')}
                      onKeyDown={(e) => { this.onNextInputListeners(e, 'username', 'input-text-password') }}
                    />
                    <FormInputFooter type={'required'} errorText={this.state.errors.username}></FormInputFooter>
                  </FormControl>

                  <FormControl fullWidth variant="standard" style={{ marginTop: 25 }}>
                    <TextField
                      id="input-text-password"
                      variant="standard"
                      type={this.state.showPassword ? 'text' : 'password'}
                      disabled={this.state.loadingLogin}
                      label={`Password`}
                      placeholder='Tuliskan password Anda'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"><Icon>password</Icon></InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.onShowPassword}
                              edge="end"
                            >
                              {this.state.showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      value={this.state.inputs.password}
                      onChange={(event) => {
                        if (event.target !== undefined && event.target.value !== undefined) {
                          this.onTextInputListeners(event.target.value, 'password')
                        }
                      }}
                      error={this.state.errors.password !== undefined && this.state.errors.password !== null ? true : false}
                      onFocus={() => this.onTextErrorListeners(null, 'password')}
                      onKeyDown={(e) => { this.onNextInputListeners(e, 'password', 'SUBMIT') }}
                    />
                    <FormInputFooter type={'required'} errorText={this.state.errors.password}></FormInputFooter>
                  </FormControl>

                  <LoadingButton
                    loading={this.state.loadingLogin}
                    fullWidth
                    sx={{ marginTop: 25, marginBottom: 15 }}
                    variant="contained"
                    loadingPosition="start"
                    startIcon={<Icon>login</Icon>}
                    onClick={() => { this.onValidateInputListeners() }}
                  >
                    Login
                  </LoadingButton>

                </Box>
              </Box>
            </div>
          </Container>
        </div>
      </>
    )
  }
}