import { CircularProgress, Icon, Menu, Skeleton, Tooltip } from "@mui/material";
import DateFilter from "components/DateFilter";
import MyChart from "components/MyChart";
import PageLayout from "components/PageLayout";
import moment from "moment";
import React from "react";
import { userAuth } from "store";
import ApiHelpers from "utils/ApiHelpers";
import CommonHelper from "utils/CommonHelper";
import Constants from "utils/Constant";

const CURRENTDATE = moment()
const THIS_WEEK_START_DATE = CURRENTDATE.clone().startOf('isoWeek').format("YYYY-MM-DD")
const THIS_WEEK_END_DATE = CURRENTDATE.clone().endOf('isoWeek').format("YYYY-MM-DD")

const inlineStyles = {
  card_info: { 
    fontSize:14, 
    paddingTop:7.5,
    marginTop:10, 
    justifyContent:'flex-end', 
    paddingRight:20, 
    borderTop:"solid 1px rgba(255,255,255,0.2)" 
  }
}

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      inputStartDate: THIS_WEEK_START_DATE,
      inputEndDate: THIS_WEEK_END_DATE,
      inputTitle: "Minggu ini",
      dataSummary: null,
      isGetSummary: true,

      chartType: 'bar',
      dataGraphTransaction: {
        label: [],
        transaction: [],
        transaction_sync: []
      },
      isGetGraphTransaction: true,

      clientSelected: {
        id: '',
        name: 'Semua Merchant',
      },
      dataClient: [],

      anchorElMerchant: null,
    };
    this.firstMount = true;
  }

  componentDidMount() {
    if (this.firstMount) {
      this.firstMount = false;
      document.title = `Dashboard | ${Constants.appName}`;
      let user = userAuth();
      this.getClient()
      this.setState({ user }, this.onRefresh);
    }
  }

	// ====================================================================================
  // ========== INITIALIZE, GET DATA ====================================================
  // ====================================================================================
  onRefresh = () => {
    this.setState({
        isGetSummary: true,
        isGetGraphTransaction: true,
    }, this.getSalesSummary)
  }
  getSalesSummary = async () => {
    let dataSummary = null
    let response = await ApiHelpers.get('dashboard/summary', {
      'client_id': this.state.clientSelected.id,
      "start_date": this.state.inputStartDate,
      "end_date": this.state.inputEndDate
    })
    if(response.status === 200 && response.results.data.jumlah !== undefined) {
        dataSummary = response.results.data
    }
    this.setState({ isGetSummary:false, dataSummary }, this.getGraphSummary)
  }
  getClient = async () => {
    let dataClient = null
    let response = await ApiHelpers.get('master/client')
    if(response.status === 200 && response.results.data !== undefined) {
      dataClient = response.results.data
      dataClient = [{id: '', name: 'Semua Merchant' }, ...dataClient]
    }
    this.setState({ dataClient })
  }
  getGraphSummary = async () => {
    let dataGraphTransaction = {
      label: [],
      transaction: [],
      transaction_sync: []
    }
    let response = await ApiHelpers.get('dashboard/graph', {
      'client_id': this.state.clientSelected.id,
      "start_date": this.state.inputStartDate,
      "end_date": this.state.inputEndDate
    })
    if(response.status === 200 && response.results.data !== undefined) {
      dataGraphTransaction = response.results.data
    }
    this.setState({ isGetGraphTransaction:false, dataGraphTransaction })
  }
  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================

  onClickMerchantListener = (e) => { this.setState({ anchorElMerchant: e.currentTarget })}
  onCloseMerchantListener = (e) => { this.setState({ anchorElMerchant: null })}
  // ====================================================================================
  // ========== RENDER SECTION ==========================================================
  // ====================================================================================

  render() {
    return (
      <PageLayout user={this.state.user || {}}>
        <div className="contents">
          <div style={{
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            justifyContent:"space-between",
            marginBottom:20,
            }}>
            <div>
              <h3>Ringkasan Laporan</h3>
              <p style={{fontSize:14, color:"#888", marginTop:3}}>
                Ringkasan laporan webhook {this.state.inputTitle}
              </p>
            </div>
            <div style={{ display: 'flex'}}>
              <Tooltip title={'Ganti Merchant'}>
                <div className={'my_btn'}
                  style={{ marginRight: 10, backgroundColor: 'var(--primary-color)'}}
                  onClick={this.onClickMerchantListener}
                >
                  <div className={'title'} style={{ marginLeft: 0}}>{this.state.clientSelected.name}</div>
                  <Icon style={{ marginLeft:10, fontSize:20, color:"#fff", marginRight:-5 }}>expand_more</Icon>
                </div>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={this.state.anchorElMerchant}
                keepMounted
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={Boolean(this.state.anchorElMerchant)}
                onClose={this.onCloseMerchantListener}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    width: 220,
                    maxWidth: 220,
                    boxShadow:
                      "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;",
                  },
                }}
              >
                {this.state.dataClient.map((item,index) => (
                  <div className={`merchant_item ${this.state.clientSelected.name === item.name ? 'active' : ''}`} onClick={() => {
                    this.setState({ clientSelected: item}, () => {
                      this.onCloseMerchantListener()
                      this.onRefresh()
                    })
                  }}>
                    {item.name}
                  </div>
                ))}
              </Menu>
              <Tooltip title="Filter Tanggal">
                <div className={'my_btn'}
                  onClick={() => {
                    this.ref_FilterDate.onSetData(this.state.inputTitle, this.state.inputStartDate, this.state.inputEndDate)
                  }}
                >
                  <Icon style={{ fontSize:18, color:"#fff" }}>today</Icon>
                  <div className={'title'}>{this.state.inputTitle}</div>
                  <Icon style={{ marginLeft:10, fontSize:20, color:"#fff", marginRight:-5 }}>expand_more</Icon>
                </div>
              </Tooltip>
            </div>
          </div>
          {this.renderSalesSummary1()}
          <div className={'ds_flex_row'} style={{ marginTop:30 }}>
            {this.renderSalesGrossSummary()}
          </div>
        </div>

        {/* DIALOG FILTER DATE */}
        <DateFilter
          ref={(value) => this.ref_FilterDate = value}
          onSelect={(params) => {
            this.setState({
              inputStartDate: params.start_date,
              inputEndDate: params.end_date,
              inputTitle: params.title
            }, this.onRefresh)
          }}
        />
      </PageLayout>
    );
  }
  renderSalesSummary1() {
    let showData = false
    let objData = this.state.dataSummary
    if(objData !== null) {
      showData = true
    }
    return(
      <div className={'ds_flex_row'} style={{color:"#fff"}}>
          <div className={'box'} style={{ 
              backgroundColor:"transparent",
              backgroundImage:"linear-gradient(167.46deg,#95a5a6 10%,#7f8c8d 80%)",
              boxShadow: "0px 7px 0px 0px #95a5a6"
              }}>
              <div className={'flex_row_center'}>
                  <div className={'ic_ctx'} style={{ backgroundColor:"#7f8c8d" }}>
                      <Icon style={{ color:"#fff" }}>receipt_long</Icon>
                  </div>
                  <h4 style={{marginLeft:15}}>Jumlah Transaksi</h4>
              </div>
              <div style={{marginLeft:63}}>
                  <span>
                      {this.state.isGetSummary ? 
                          <Skeleton width={120} height={27}/> 
                          :
                          <h3>{showData ? CommonHelper.formatNumber(objData.jumlah) : "-"}</h3>
                      }
                  </span>
                  {this.state.isGetSummary ? <div className={'flex_row_center'} style={inlineStyles.card_info}> 
                      Loading...
                      </div> :
                      <div className={'flex_row_center'} style={inlineStyles.card_info}>
                          <Icon style={{ color:"#fff", fontSize:16 }}>event</Icon>
                          <p>&nbsp;{this.state.inputTitle}</p>
                      </div>
                  }
              </div>
          </div>
          <div className={'box'} style={{ 
              backgroundColor:"transparent",
              backgroundImage:"linear-gradient(167.46deg,#fa8231 10%,#fd9644 80%)",
              boxShadow: "0px 7px 0px 0px #fa8231"
              }}>
              <div className={'flex_row_center'}>
                  <div className={'ic_ctx'} style={{ backgroundColor:"#fd9644" }}>
                      <Icon style={{ color:"#fff" }}>receipt_long</Icon>
                  </div>
                  <h4 style={{marginLeft:15}}>Nominal Transaksi</h4>
              </div>
              <div style={{marginLeft:63}}>
                  <span>
                      {this.state.isGetSummary ? 
                          <Skeleton width={120} height={27}/> 
                          :
                          <h3>{showData ? CommonHelper.formatNumber(objData.nominal, 'idr') : "-"}</h3>
                      }
                  </span>
                  {this.state.isGetSummary ? <div className={'flex_row_center'} style={inlineStyles.card_info}> 
                      Loading...
                      </div> :
                      <div className={'flex_row_center'} style={inlineStyles.card_info}>
                          <Icon style={{ color:"#fff", fontSize:16 }}>event</Icon>
                          <p>&nbsp;{this.state.inputTitle}</p>
                      </div>
                  }
              </div>
          </div>
          <div className={'box'} style={{ 
              backgroundColor:"transparent",
              backgroundImage:"linear-gradient(167.46deg,#ffa801 10%,#ffc048 80%)",
              boxShadow: "0px 7px 0px 0px #ffa801"
              }}>
              <div className={'flex_row_center'}>
                  <div className={'ic_ctx'} style={{ backgroundColor:"#ffc048" }}>
                      <Icon style={{ color:"#fff" }}>receipt_long</Icon>
                  </div>
                  <h4 style={{marginLeft:15}}>Transaksi Ter-Sinkronisasi</h4>
              </div>
              <div style={{marginLeft:63}}>
                  <span>
                      {this.state.isGetSummary ? 
                          <Skeleton width={120} height={27}/> 
                          :
                          <h3>{showData ? CommonHelper.formatNumber(objData.jumlah_sync) : "-"}</h3>
                      }
                  </span>
                  {this.state.isGetSummary ? <div className={'flex_row_center'} style={inlineStyles.card_info}> 
                      Loading...
                      </div> :
                      <div className={'flex_row_center'} style={inlineStyles.card_info}>
                          <Icon style={{ color:"#fff", fontSize:16 }}>event</Icon>
                          <p>&nbsp;{this.state.inputTitle}</p>
                      </div>
                  }
              </div>
          </div>
          <div className={'box'} style={{ 
              backgroundColor:"transparent",
              backgroundImage:"linear-gradient(167.46deg,#f53b57 10%,#ef5777 80%)",
              boxShadow: "0px 7px 0px 0px #f53b57"
              }}>
              <div className={'flex_row_center'}>
                  <div className={'ic_ctx'} style={{ backgroundColor:"#ef5777" }}>
                      <Icon style={{ color:"#fff" }}>receipt_long</Icon>
                  </div>
                  <h4 style={{marginLeft:15}}>Nominal Ter-Sinkronisasi</h4>
              </div>
              <div style={{marginLeft:63}}>
                  <span>
                      {this.state.isGetSummary ? 
                          <Skeleton width={120} height={27}/> 
                          :
                          <h3>{showData ? CommonHelper.formatNumber(objData.nominal_sync, 'idr') : "-"}</h3>
                      }
                  </span>
                  {this.state.isGetSummary ? <div className={'flex_row_center'} style={inlineStyles.card_info}> 
                      Loading...
                      </div> :
                      <div className={'flex_row_center'} style={inlineStyles.card_info}>
                          <Icon style={{ color:"#fff", fontSize:16 }}>event</Icon>
                          <p>&nbsp;{this.state.inputTitle}</p>
                      </div>
                  }
              </div>
          </div>
      </div>
    )
  }
  renderSalesGrossSummary(){
    let arrData = this.state.dataGraphTransaction
    

    let categories = arrData.label
    let series = [
      {
        name: 'Transksi',
        data: arrData.transaction
      },
      {
        name: 'Transksi Sync',
        data: arrData.transaction_sync
      },
    ]

    return(
      <div className={'box'} style={{ height:370, boxShadow: "0px 7px 0px 0px rgba(50,50,50,0.05)" }}>
        <div className={'flex_row_center'}>
          <div className={'ic_ctx'} style={{ backgroundColor:"rgba(0,0,0,0.06)" }}>
            <Icon style={{ color:"#888" }}>equalizer</Icon>
          </div>
          <div style={{marginLeft:15, flex:1}}>
            <h4 style={{ fontSize:15 }}>Grafik Transaksi</h4>
            <p style={{fontSize:12, color:"#888", marginTop:1, textTransform:"capitalize"}}>
              Grafik Transaksi {this.state.inputTitle}
            </p>
          </div>
          {arrData.label.length > 0 &&
            <div style={{ display:"inline-flex", alignItems:"center", justifyContent:"center" }}>
              <div 
                style={{ cursor:"pointer", marginRight:15, fontSize:14,
                  color:this.state.chartType === "bar" ? 'var(--primary-color)' : "#999"
                }}
                onClick={() => { this.setState({ chartType:"bar" }) }}
                >
                Bar Chart
              </div>
              <div 
                style={{ cursor:"pointer", marginRight:22, fontSize:14,
                  color:this.state.chartType === "line" ? 'var(--primary-color)' : "#999"
                }}
                onClick={() => { this.setState({ chartType:"line" }) }}
                >
                Line Chart
              </div>
            </div>
          }
        </div>
        <div style={{ marginTop:"10px", paddingLeft:"10px", paddingRight:"15px" }}>
          {this.state.isGetGraphTransaction &&
            <div style={{ width:"100%", height:"280px", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center" }}>
              <CircularProgress color={"primary"} size={36}/>
              <div style={{marginTop:10, color:"rgba(0,0,0,0.5)", fontSize:12}}>Loading data...</div>
            </div>
          }
          {!this.state.isGetGraphTransaction && arrData.label.length <= 0 &&
            <div style={{ width:"100%", height:"280px", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center" }}>
              <img
                className=""
                src="/assets/images/img_empty_data.png"
                alt="sodapos.com"
                width={100}
                height={100}
                priority
              />
              <div style={{marginTop:10, color:"rgba(0,0,0,0.5)", fontSize:12}}>Tidak ada data ditampilkan</div>
            </div>
          }
          {!this.state.isGetGraphTransaction && arrData.label.length > 0 &&
            <MyChart 
              ref={(value) => this.ref_MyChart_Transaction = value}
              chartType={this.state.chartType}
              plugin="apexcharts"
              width="100%"
              height="300px"
              dataSeries={series}
              dataCategory={categories}
              showLegend
              // tickAmountX={4}
              // tickAmountY={6}
              colors={['#fd9644', "#2d98da"]}
              useFormater
              // forecast={1}
            />
          }
        </div>
      </div>
    )
  }
}
