import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { userAuth } from "../store"

function Guest(props) {
    const navigate = useNavigate();
    const auth = userAuth()

    useEffect(() => {
        if(auth !== null && auth.id !== undefined && auth.id !== "") {
            navigate('/dashboard')
        }
    }, [auth, navigate])

    return props.render
}

export default Guest