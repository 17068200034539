/*
Created by esoda
Created on Dec, 2023
Contact esoda.id
Last modified: -
*/

import React from "react";
import toRupiah from '@develoka/angka-rupiah-js';
import ReactApexChart from 'react-apexcharts';

// https://apexcharts.com/docs/options/yaxis/
// https://www.react-google-charts.com/examples/bar

export default class MyChart extends React.Component {
    // ====================================================================================
    // ========== RENDER SECTIONS =========================================================
    // ====================================================================================
    render() {
        return(
            this.renderChart()
        )
    }
    renderChart() {
        if(this.props.plugin === "apexcharts") {
            let series = this.props.dataSeries
            let categories = this.props.dataCategory
            let markers =  this.props.chartType === "line" ? { size: 4 } : {}
            let stroke =  this.props.chartType === "line" ? {  show: true,
                curve: 'smooth',
                lineCap: 'round',
                colors: undefined,
                width: 2.5,
                dashArray: 0, } : {}

            let options= {
                chart: { 
                    id: undefined, 
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: false,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                            reset: false
                        }
                    }
                },
                xaxis: {
                    type: "category",
                    categories: categories,
                    tickAmount: this.props.tickAmountX || 4,
                    tickPlacement: 'on',
                    position: 'bottom',
                    labels: {
                        show: true,
                        rotate: 0,
                        hideOverlappingLabels: true,
                        trim: false,
                        style: { fontSize: '10px' }
                    },
                },
                yaxis: {
                    show: true,
                    tickAmount: this.props.tickAmountY || 5,
                    labels: {
                        show: true,
                        align: 'right',
                        style: {
                            colors: ["#505050"],
                            fontSize: '10px',
                        },
                        formatter: (value) => {
                            if(this.props.useFormater) {
                                return toRupiah(value, {symbol: false, useUnit: true, floatingPoint: 0})
                            } else {
                                return value
                            }
                        },
                    },
                },
                dataLabels: { enabled: false },
                colors: this.props.colors,
                forecastDataPoints: { count: this.props.forecast ? this.props.forecast : 0, fillOpacity: 0.5 },
                grid: {
                    show: true,
                    borderColor: '#ebebeb',
                    strokeDashArray: 5,
                    padding: { top: 10, right: this.props.chartType === "line" ? 5 : 0, bottom: 0, left: this.props.chartType === "line" ? 15 :15 },
                },
                legend: {
                    show: this.props.showLegend ? true : false,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'top',
                    fontSize: '13px',
                    labels: {
                        colors: "#505050",
                        useSeriesColors: false
                    },
                    markers: { width: 8, height: 8, radius: 2 },
                },
                markers,
                stroke
            }
            return(
                <ReactApexChart 
                    options={this.props.options || options} 
                    series={series} 
                    type={this.props.chartType}
                    width={this.props.width}
                    height={this.props.height}
                />
            )
        }
    }
}