/*
Created by esoda
Created on Nov, 2023
Contact esoda.id
*/

const CommonHelper = {
    greating: () => {
        var today = new Date()
        var curHr = today.getHours()
        if (curHr > 3 && curHr < 12) {
            return 'Selamat pagi'
        } else if (curHr >= 12 && curHr < 16) {
            return 'Selamat siang'
        } else if (curHr >= 16 && curHr < 18) {
            return 'Selamat sore'
        } else {
            return 'Selamat malam'
        }
    },
    initialName: (value) => {
        if (!value) return ""
        let splitName = value.split(" ")
        let initial = value.charAt(0)
        if (splitName[1] !== undefined) {
            initial += splitName[1].charAt(0)
        }

        return initial.toUpperCase()
    },
    formatNumber: (amount, format = null) => {
        let result;
        if (amount !== null) {
            amount = Math.ceil(amount)
            result = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
            result = amount;
        }
        let prefix;
        if (format === 'idr') {
            prefix = 'Rp';
        } else {
            prefix = '';
        }

        return prefix + result;
    },
    capitalize: (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getDateRangeInMonth: (month, year) => {
        // Result will return start & end date in a selected month and year
        let dateCount = new Date(year, month, 0).getDate()
        let startDate = ""
        let endDate = ""

        for (let index = 1; index <= dateCount; index++) {
            let date = index > 9 ? index : `0${index}`
            startDate = `${year}-${month}-01`
            endDate = `${year}-${month}-${date.toString()}`
        }
        return { startDate, endDate }
    },
    getStateColor: (type) => {
        let opacity = 0.3
        let color = {
            background: `rgba(127, 140, 141, ${opacity})`,
            color: 'rgba(127, 140, 141, 1)'
        }

        if (type === "pending") {
            color = {
                background: `rgba(230, 126, 34, ${opacity})`,
                color: 'rgba(230, 126, 34, 1)'
            }
        }
        if (type === "paid" || type === "completed") {
            color = {
                background: `rgba(22, 160, 133, ${opacity})`,
                color: 'rgba(22, 160, 133, 1)'
            }
        }
        if (type === "failed") {
            color = {
                background: `rgba(231, 76, 60, ${opacity})`,
                color: 'rgba(231, 76, 60, 1)'
            }
        }

        return color
    },
}

export default CommonHelper