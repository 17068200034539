import { Alert, Snackbar } from "@mui/material"
import Loading from "components/Loading"
import React from "react"
import ApiHelpers from "utils/ApiHelpers"

export default class OauthCallback extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      showSnackbar: false,
      severitySnackbar: '',
      messageSnackbar: '',
    }
    this.firstMount = true
  }

  componentDidMount(){
    if(this.firstMount){
      this.firstMount = false
      let params = this.searchToObject()
      if(params.authorize_key !== undefined && params.code !== undefined){
        this.onSaveData(params.authorize_key, params.code)
      }

    }
  }

  componentWillUnmount() { }

  // ====================================================================================
  // ========== INITIALIZE, GET DATA ====================================================
  // ====================================================================================
  searchToObject = () => {
    let pairs = window.location.search.substring(1).split("&"),
      obj = {},
      pair,
      i;

    for ( i in pairs ) {
      if ( pairs[i] === "" ) continue;

      pair = pairs[i].split("=");
      obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }

    return obj;
  }
  onSaveData = async (authorize_key, code) => {
    this.ref_LoadingDialog.onShowDialog();
    let response = await ApiHelpers.post("client/accurate_auth_callback", {
      authorize_key,
      code,
      redirect_uri: `${window.location.origin}${window.location.pathname}?authorize_key=${authorize_key}`
    })
    if(response.status === 200){
      this.ref_LoadingDialog.onCloseDialog();
      this.setState({
        showSnackbar: true,
        severitySnackbar: "success",
        messageSnackbar: "Berhasil Ijinkan Aplikasi.",
      });
      setTimeout(() => {
        window.location.href = window.location.origin + "/merchant";
      }, 4600);
    }else{
      this.ref_LoadingDialog.onCloseDialog();
      this.setState({
        showSnackbar: true,
        severitySnackbar: "error",
        messageSnackbar: "Gagal Ijinkan Aplikasi.",
      });
      setTimeout(() => {
        window.location.href = window.location.origin + "/merchant";
      }, 4600);
    }
  }
  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================

  // ====================================================================================
  // ========== RENDER SECTION ==========================================================
  // ====================================================================================
  render(){
    return(
      <>
        {/* LOADING DIALOG */}
        <Loading ref={(value) => (this.ref_LoadingDialog = value)} />

        {/* SNACKBAR & ALERT DIALOG */}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          style={{ bottom: 80 }}
          open={this.state.showSnackbar}
          autoHideDuration={4600}
          onClose={() => {
            this.setState({ showSnackbar: false, messageSnackbar: "" });
          }}
        >
          <Alert
            severity={this.state.severitySnackbar}
            onClose={() => {
              this.setState({ showSnackbar: false, messageSnackbar: "" });
            }}
            sx={{ width: "100%" }}
          >
            {this.state.messageSnackbar}
          </Alert>
        </Snackbar>
      </>
    )
  }
}