/**
 * @link https://github.com/exceljs/exceljs
 */

export const EXPORT_EXCEL_STYLE_NUM_FMT = {
  number: "#,##0",
  currencyRp: "Rp#,##0",
};

export default class ExportExcel {
  _COL_MAX_WIDTH = 200;
  _FILE_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  _EXCEL_EXTENSION = ".xlsx";

  _ExcelJS = require("exceljs");
  _config = [];
  _refConfigIndex = {};
  _headerConfig = {};
  _colConfig = {};
  _colCalcWidth = {};
  _refColConfigInData = {};
  _summary = [];
  _summaryCalc = {};
  _data = [];
  _customWrite = [];
  _title = "";
  _titleConfig = {};
  _subTitleArray = "";
  _filename = "";

  _curRow = 1;
  _rowHeaderStart = 1;
  _rowDataStart = 1;

  _workbook = null;
  _sheet = null;
  _worksheet = null;

  /**
   *
   * @param {Array.<Object>} params.data = [
   *  {
   *    _refColConfig: "key_col_config", // ambil konfigurasi kolom dari referensi,
   *                                     // bisa digabung dengan _colConfig [disupport baru config per key] di data untuk kasus config dinamis
   *    _colConfig: {
   *      _global: {
   *        style: { // prioritas utama dari config style yang lain @link https://github.com/exceljs/exceljs?tab=readme-ov-file#styles
   *        },
   *      },
   *      key: {
   *        style: { // prioritas config ini daripada config global, prioritas utama dari config style yang lain @link https://github.com/exceljs/exceljs?tab=readme-ov-file#styles
   *        },
   *        merge: {
   *          startRow: 0, // baris mulai merge, row sekarang + startRow, 0 = di baris data ini
   *          startCol: 1, // kolom mulai merge, kolom A = 1
   *          endRow: 0, // baris selesai merge, row sekarang + endRow, 0 = di baris data ini
   *          endCol: 1, // kolom selesai merge, kolom A = 1
   *        },
   *      },
   *    },
   *    key: "value",
   *  }
   * ]
   *
   * @param {Array.<Object>} params.summary = [
   *  {
   *    _refColConfig: "", // key di refColConfigInData
   *    keyConfig: "", // key data di refColConfigInData
   *    merge: {
   *      startCol: 1,
   *      endCol: 1,
   *    }, // config merge, object kosong atau tidak dikirim untuk tanpa merge kolom
   *    value: "", // nilai summary
   *    calcFromKey: "", // dikalkulasi dari data dengan key
   *    style: { // @link https://github.com/exceljs/exceljs?tab=readme-ov-file#styles
   *    }
   *  }
   * ]
   *
   * @param {Array.<Object>} params.customWrite = [
   *  {
   *    _refColConfig: "", // style, merge
   *    value: ["col A", "col B"], // nilai write
   *  ]
   * ]
   * dimulai setelah writeSummary
   *
   * @param {Array.<Object>} params.config = [
   *  {
   *    header: "", // label header
   *    key: "", // nama key di data
   *    width: 1, // set panjang kolom manual/tidak dikalkulasi
   *  }
   * ]
   *
   * @param {Object} params.headerConfig = {
   *  _global: {
   *    style: { // @link https://github.com/exceljs/exceljs?tab=readme-ov-file#styles
   *    },
   *  },
   *  key: { // dari nama key di data, key menjadi prioritas daripada global
   *    style: { // @link https://github.com/exceljs/exceljs?tab=readme-ov-file#styles
   *    },
   *  }
   * }
   *
   * @param {Object} params.refColConfigInData = { // referensi config kolom di data atau summary, untuk mengurangi besar data
   *  key_col_config: {
   *    _global: {
   *      style: { // @link https://github.com/exceljs/exceljs?tab=readme-ov-file#styles
   *      },
   *    },
   *    key: { // dari nama properti di data, key menjadi prioritas daripada global
   *      style: { // @link https://github.com/exceljs/exceljs?tab=readme-ov-file#styles
   *      },
   *    }
   *  }
   * }
   * }
   *
   * @param {Object} params.colConfig = {
   *  _global: {
   *    style: { // @link https://github.com/exceljs/exceljs?tab=readme-ov-file#styles
   *    },
   *  },
   *  key: { // dari nama properti di data, key menjadi prioritas daripada global
   *    style: { // @link https://github.com/exceljs/exceljs?tab=readme-ov-file#styles
   *    },
   *  }
   * }
   *
   * @param {string} params.title = ""
   *
   * @param {Object} params.titleConfig = {
   *  style: { // @link https://github.com/exceljs/exceljs?tab=readme-ov-file#styles
   *  },
   * }
   *
   * @param {Array<Object>} params.subTitleArray = [
   *  {
   *    value: ""
   *    style: { // @link https://github.com/exceljs/exceljs?tab=readme-ov-file#styles
   *    }
   *  }
   * ]
   */
  constructor(params) {
    const {
      data = [],
      summary = [],
      customWrite = [],
      config = [],
      headerConfig = {},
      refColConfigInData = {},
      colConfig = {},
      title = "",
      titleConfig = {
        style: {
          font: {
            size: 12,
            bold: true,
          },
        },
      },
      subTitleArray = [],
      filename = "",
    } = params;

    this._config = config;
    this._colConfig = colConfig;
    this._refColConfigInData = refColConfigInData;
    this._headerConfig = headerConfig;
    this._summary = summary;
    this._data = data;
    this._customWrite = customWrite;
    this._title = title;
    this._title = title;
    this._titleConfig = titleConfig;
    const dateTIme = new Date();
    const formatter = new Intl.DateTimeFormat("id-ID", {
      dateStyle: "full",
      timeStyle: "short",
    });
    const formattedDateTime = formatter.format(dateTIme);
    subTitleArray.unshift({
      value: `Tanggal Ekspor: ${formattedDateTime}`,
    });
    this._subTitleArray = subTitleArray;
    this._filename = filename;

    if (this._config.length > 0) {
      this._config.forEach((element, index) => {
        this._refConfigIndex[element.key] = index;
      });
    }

    if (this._summary.length > 0) {
      this._summary.forEach((element, index) => {
        if (element?.calcFromKey) {
          this._summaryCalc[element.calcFromKey] = 0;
        }
      });
    }

    this._workbook = new this._ExcelJS.Workbook();
    this._sheet = this._workbook.addWorksheet(this._title.substring(0, 30), {
      views: [{ showGridLines: false }],
    });
    this._worksheet = this._workbook.getWorksheet(this._sheet.name);

    this._worksheet.columns = this._config;
  }

  writeTitleAndSubTitle() {
    // write title
    this._worksheet.insertRow(this._curRow, [this._title]);
    this._worksheet.getRow(this._curRow).getCell(1).style =
      this._titleConfig.style;
    this._curRow++;

    // write sub title
    if (this._subTitleArray.length > 0) {
      this._subTitleArray.forEach((element) => {
        if (element?.value) {
          this._worksheet.insertRow(this._curRow, [element.value]);
          if (
            element.style !== undefined &&
            Object.keys(element.style).length > 0
          ) {
            this._worksheet.getRow(this._curRow).getCell(1).style =
              element.style;
          }
          this._curRow++;
        }
      });
    }

    // write space kosong
    this._worksheet.insertRow(this._curRow, []);
    this._curRow++;

    this._rowHeaderStart = this._curRow;
    this._rowDataStart = this._curRow + 1;
  }

  writeData() {
    // if (this._data.length > 0) {
    // insert data
    this._worksheet.addRows(this._data);

    // get last row
    this._curRow = this._worksheet.rowCount + 1;

    // style, calc width
    this._worksheet.columns.forEach((col) => {
      // console.log("col", col);

      let curDataIndex = 0;
      col.eachCell((cell) => {
        // console.log("cell", cell);
        // cell.value = +cell.value // convert a string value to a number value

        const cellValue = cell._value.model.value;
        let cellValueWidth = 0;
        if (typeof cellValue != "string") {
          cellValueWidth = String(cellValue).length * 1.15;
        } else {
          cellValueWidth = cellValue.length * 1.15;
        }

        // hanya di header
        if (cell._row._number == this._rowHeaderStart) {
          // config per key column
          if (this._headerConfig[col._key] !== undefined) {
            // apply style
            if (
              this._headerConfig[col._key].style !== undefined &&
              Object.keys(this._headerConfig[col._key].style).length > 0
            ) {
              cell.style = this._headerConfig[col._key].style;
            }
          }
          // config global
          else if (this._headerConfig._global !== undefined) {
            // apply style
            if (
              this._headerConfig._global.style !== undefined &&
              Object.keys(this._headerConfig._global.style).length > 0
            ) {
              cell.style = this._headerConfig._global.style;
            }
          }
        }

        // sudah di header
        if (cell._row._number >= this._rowHeaderStart) {
          // console.log(this._colConfig[col._key]);
          if (this._colCalcWidth[col._key] === undefined) {
            this._colCalcWidth[col._key] = {};
          }

          if (this._colCalcWidth[col._key].width === undefined) {
            this._colCalcWidth[col._key].width = 1;
          }
          // calc width
          // console.log(cellValue, cellValueWidth);
          if (cellValueWidth > this._colCalcWidth[col._key].width) {
            this._colCalcWidth[col._key].width = cellValueWidth;
          }
        }

        // sudah di data
        if (cell._row._number >= this._rowDataStart) {
          const curData = this._data[curDataIndex];

          if (curData !== undefined) {
            // console.log("current data", curData);

            // calc nilai kolom
            if (this._summaryCalc[col._key] !== undefined) {
              if (typeof cellValue != "number") {
                this._summaryCalc[col._key] += Number(cellValue);
              } else {
                this._summaryCalc[col._key] += cellValue;
              }
            }

            // config dari data ke referensi
            let isConfigFromRef = false;
            let refConfigInData = {};
            if (
              this._refColConfigInData !== undefined &&
              Object.keys(this._refColConfigInData).length > 0 &&
              curData._refColConfig !== undefined &&
              this._refColConfigInData[curData._refColConfig] !== undefined
            ) {
              isConfigFromRef = true;
              refConfigInData = this._refColConfigInData[curData._refColConfig];
            }

            // config dari data ke referensi
            if (isConfigFromRef) {
              // apply config per key
              if (refConfigInData[col._key] !== undefined) {
                // apply style per key
                if (
                  refConfigInData[col._key].style !== undefined &&
                  Object.keys(refConfigInData[col._key].style).length > 0
                ) {
                  cell.style = refConfigInData[col._key].style;
                }

                // apply merge
                if (
                  refConfigInData[col._key].merge !== undefined &&
                  Object.keys(refConfigInData[col._key].merge).length > 0 &&
                  refConfigInData[col._key].merge.startRow !== undefined &&
                  refConfigInData[col._key].merge.startCol !== undefined &&
                  refConfigInData[col._key].merge.endRow !== undefined &&
                  refConfigInData[col._key].merge.endCol !== undefined
                ) {
                  // merge by start row, start column, end row, end column
                  // console.log(
                  //   curData,
                  //   cell._row.number + refConfigInData[col._key].merge.startRow,
                  //   refConfigInData[col._key].merge.startCol,
                  //   cell._row.number + refConfigInData[col._key].merge.endRow,
                  //   refConfigInData[col._key].merge.endCol,
                  //   );
                  this._worksheet.mergeCells(
                    cell._row.number + refConfigInData[col._key].merge.startRow,
                    refConfigInData[col._key].merge.startCol,
                    cell._row.number + refConfigInData[col._key].merge.endRow,
                    refConfigInData[col._key].merge.endCol
                  );
                }
              }
              // apply config global
              else if (refConfigInData._global !== undefined) {
                // apply style global
                if (
                  refConfigInData._global.style !== undefined &&
                  Object.keys(refConfigInData._global.style).length > 0
                ) {
                  cell.style = refConfigInData._global.style;
                }
              }

              // cell membutuhkan config lagi per data
              // config di data dinamis
              // yang disupport sekarang baru config per key
              if (curData._colConfig !== undefined) {
                // apply config per key
                if (curData._colConfig[col._key] !== undefined) {
                  // apply style per key
                  if (
                    curData._colConfig[col._key].style !== undefined &&
                    Object.keys(curData._colConfig[col._key].style).length > 0
                  ) {
                    cell.style = curData._colConfig[col._key].style;
                  }

                  // // apply merge
                  if (
                    curData._colConfig[col._key].merge !== undefined &&
                    Object.keys(curData._colConfig[col._key].merge).length >
                      0 &&
                    curData._colConfig[col._key].merge.startRow !== undefined &&
                    curData._colConfig[col._key].merge.startCol !== undefined &&
                    curData._colConfig[col._key].merge.endRow !== undefined &&
                    curData._colConfig[col._key].merge.endCol !== undefined
                  ) {
                    // merge by start row, start column, end row, end column
                    // console.log(
                    //   curData,
                    //   cell._row.number + curData._colConfig[col._key].merge.startRow,
                    //   curData._colConfig[col._key].merge.startCol,
                    //   cell._row.number + curData._colConfig[col._key].merge.endRow,
                    //   curData._colConfig[col._key].merge.endCol,
                    //   );
                    this._worksheet.mergeCells(
                      cell._row.number +
                        curData._colConfig[col._key].merge.startRow,
                      curData._colConfig[col._key].merge.startCol,
                      cell._row.number +
                        curData._colConfig[col._key].merge.endRow,
                      curData._colConfig[col._key].merge.endCol
                    );
                  }
                }
              }
            }
            // config dari data
            else if (curData._colConfig !== undefined) {
              // apply config per key
              if (curData._colConfig[col._key] !== undefined) {
                // apply style per key
                if (
                  curData._colConfig[col._key].style !== undefined &&
                  Object.keys(curData._colConfig[col._key].style).length > 0
                ) {
                  cell.style = curData._colConfig[col._key].style;
                }
              }
              // apply config global
              else if (curData._colConfig._global !== undefined) {
                // apply global style
                if (
                  curData._colConfig._global.style !== undefined &&
                  Object.keys(curData._colConfig._global.style).length > 0
                ) {
                  cell.style = curData._colConfig._global.style;
                }
              }
            }
            // config per key column
            else if (this._colConfig[col._key] !== undefined) {
              // apply style
              if (
                this._colConfig[col._key].style !== undefined &&
                Object.keys(this._colConfig[col._key].style).length > 0
              ) {
                cell.style = this._colConfig[col._key].style;
              }
            }
            // config global
            else if (this._colConfig._global !== undefined) {
              // apply style
              if (
                this._colConfig._global.style !== undefined &&
                Object.keys(this._colConfig._global.style).length > 0
              ) {
                cell.style = this._colConfig._global.style;
              }
            }

            curDataIndex++;
          }
        }
      });
      // }
    });

    // set column width
    // console.log(this._colConfig);
    this._worksheet.columns.forEach((col) => {
      let configIndex = this._refConfigIndex[col.key];
      let config = this._config[configIndex];
      if (
        this._colCalcWidth[col._key] !== undefined &&
        this._colCalcWidth[col._key].width !== undefined &&
        config.width === undefined
      ) {
        this._worksheet.getColumn(col._number).width =
          this._colCalcWidth[col._key].width > this._COL_MAX_WIDTH
            ? this._COL_MAX_WIDTH
            : this._colCalcWidth[col._key].width;
      }
    });
    // }
  }

  writeSummary() {
    if (this._summary.length > 0) {
      let curCol = 1;
      this._summary.forEach((element) => {
        // insert value
        if (
          element?.calcFromKey &&
          this._summaryCalc[element?.calcFromKey] !== undefined
        ) {
          this._worksheet.getRow(this._curRow).getCell(curCol).value =
            this._summaryCalc[element?.calcFromKey];
        } else if (element.value !== undefined) {
          this._worksheet.getRow(this._curRow).getCell(curCol).value =
            element.value;
        }

        // config dari data ke referensi
        let isConfigFromRef = false;
        let refConfigInData = {};
        if (
          this._refColConfigInData !== undefined &&
          Object.keys(this._refColConfigInData).length > 0 &&
          element._refColConfig !== undefined &&
          this._refColConfigInData[element._refColConfig] !== undefined
        ) {
          isConfigFromRef = true;
          refConfigInData = this._refColConfigInData[element._refColConfig];
        }

        // config dari data ke referensi
        if (isConfigFromRef) {
          if (refConfigInData._global !== undefined) {
            if (refConfigInData._global.style !== undefined) {
              this._worksheet.getRow(this._curRow).getCell(curCol).style =
                refConfigInData._global.style;
            }
          }
          // config per key
          if (
            element.keyConfig !== undefined &&
            refConfigInData[element.keyConfig] !== undefined
          ) {
            if (refConfigInData[element.keyConfig].style !== undefined) {
              this._worksheet.getRow(this._curRow).getCell(curCol).style =
                refConfigInData[element.keyConfig].style;
            }
          }
        }
        // config dari data
        else {
          // apply style
          if (
            element.style !== undefined &&
            Object.keys(element.style).length > 0
          ) {
            this._worksheet.getRow(this._curRow).getCell(curCol).style =
              element.style;
          }
        }

        // merge column
        if (
          element.merge !== undefined &&
          Object.keys(element.merge).length > 0 &&
          element.merge.startCol !== undefined &&
          element.merge.endCol !== undefined
        ) {
          // merge by start row, start column, end row, end column
          // console.log("MERGE");
          // console.log(this._curRow, element.merge.startCol, this._curRow, element.merge.endCol);
          this._worksheet.mergeCells(
            this._curRow,
            element.merge.startCol,
            this._curRow,
            element.merge.endCol
          );
          curCol = element.merge.endCol + 1;
        } else {
          curCol++;
        }
      });
    }
    this._curRow++;
  }

  /**
   * write per row dengan nilai array per kolom
   */
  writeCustom() {
    if (this._customWrite.length > 0) {
      this._customWrite.forEach((element) => {
        for (let curCol = 1; curCol <= element.value.length + 1; curCol++) {
          const valueIndex = curCol - 1;

          // config
          if (
            element._refColConfig !== undefined &&
            this._refColConfigInData[element._refColConfig] !== undefined &&
            this._refColConfigInData[element._refColConfig][valueIndex] !==
              undefined
          ) {
            // apply style dari reference
            if (
              this._refColConfigInData[element._refColConfig][valueIndex]
                .style !== undefined
            ) {
              this._worksheet.getRow(this._curRow).getCell(curCol).style =
                this._refColConfigInData[element._refColConfig][
                  valueIndex
                ].style;
            }
          }
          this._worksheet.getRow(this._curRow).getCell(curCol).value =
            element.value[valueIndex];
        }
        this._curRow++;
      });
    }
  }

  async downloadFile() {
    this.writeTitleAndSubTitle();
    this.writeData();
    this.writeSummary();
    this.writeCustom();

    // download file
    const buffer = await this._workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: this._FILE_TYPE });
    if (navigator?.msSaveBlog) {
      navigator.msSaveBlog(blob, `${this._filename}${this._EXCEL_EXTENSION}`);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `${this._filename}${this._EXCEL_EXTENSION}`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
