/*
Created by esoda
Created on Des, 2023
Contact esoda.id
Last modified: -
    - Read changelog.md
*/

import React from 'react';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

const TextFieldNumberFormat = React.forwardRef(function TextFieldNumberFormat(
    props,
    ref,
) {
    const { onChange, prefix, max, ...other } = props;
    
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isAllowed={(values) => {
                const { floatValue } = values
                if (floatValue !== undefined) {
                    if (max !== undefined) {
                        return floatValue <= Number(max)
                    } else {
                        return true
                    }
                } else {
                    return true
                }
            }}
            allowNegative={false}
            allowLeadingZeros={true}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={0}
            valueIsNumericString
            prefix={prefix || ''}
        />
    );
});

TextFieldNumberFormat.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default TextFieldNumberFormat